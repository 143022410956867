<template>
  <div>
    <Row>
      <h2>课程详情：{{ lessonDetails ? lessonDetails.name : '空' }}</h2>
      <router-link to="/lessons">返回</router-link>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Input v-model="searchValue" clearable placeholder="请输入课程名称" :style="{marginRight:'5px',width:'300px'}"></Input>
        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>

        <Divider type="vertical"/>
        <Button type="primary" @click="openAddItemModel">新增课程</Button>
      </Col>
    </Row>

    <br>


    <Row>
      <el-table :data="tableData" border :cell-style="cellStyle" size="small" default-expand-all row-key="id"
                :tree-props="{children: 'children'}">
        <el-table-column prop="name" label="课程名称"></el-table-column>
        <el-table-column prop="category" label="课程类型" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.category==2">实操视频</span>
            <span v-else>理论学习</span>
          </template>
        </el-table-column>
        <el-table-column prop="video" label="视频地址"></el-table-column>
        <el-table-column prop="video_no" label="视频编号"></el-table-column>
        <el-table-column prop="video_duration" label="时长"></el-table-column>
        <el-table-column prop="rank" label="排序"></el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <Button @click="openEditItemModel(scope.row)" :style="{marginRight:'5px'}" type="primary" size="small">
              编辑
            </Button>
            <Button @click="delItem(scope.row)" type="error" size="small">删除</Button>
          </template>
        </el-table-column>
      </el-table>
    </Row>

    <Modal title="新增编辑课程" v-model="editModel">
      <Form :model="oneItem" :label-width="100">

        <FormItem label="所属课程">
          <my-select v-model="oneItem.lesson_id" type="lessons"></my-select>
        </FormItem>

        <FormItem label="课程名称">
          <Input v-model="oneItem.name"></Input>
        </FormItem>

        <FormItem label="视频时长(秒)">
          <Input type="number" v-model="oneItem.video_duration"></Input>
        </FormItem>

        <FormItem label="视频地址">
          <Input v-model="oneItem.video"></Input>
        </FormItem>
        <FormItem label="视频编号">
          <Input v-model="oneItem.video_no"></Input>
        </FormItem>

        <FormItem label="排序(值越大越靠前)">
          <Input type="number" v-model="oneItem.rank"></Input>
        </FormItem>
      </Form>
      <div slot="footer" class="dialog-footer">
        <Button @click="editModel = false">取 消</Button>
        <Button type="primary" @click="editItem">确 定</Button>
      </div>


    </Modal>


  </div>
</template>

<script>

import MySelect from "@/components/my-select.vue";

export default {
  components: {
    MySelect
  },
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      tableData: [],

      lessonId: 0,
      lessonDetails: {},

      subject_id: 0,
      searchValue: '', // 搜索值

      oneItem: {
        name: '',
        intro: '',
        video: '',
        video_no: '',
        video_duration: 0,
        pid: 0,
        rank: 0,
        lesson_id: 0,
      },
      editModel: false, // 新增编辑
      detailsModel: false, // 详情弹窗
    }
  },
  created() {
    this.lessonId = this.$route.params.id ? this.$route.params.id : this.$route.query.id
    if (undefined === this.lessonId || "" === this.lessonId) {
      this.$router.push("/lessons")
    }
    this.getLessonDetails();
    this.getDataList();
  },

  methods: {
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },
    getLessonDetails() {
      this.$http.get("/backend/lesson/" + this.lessonId + "/details").then(res => {
        this.lessonDetails = res.data.data;
      });
    },
    getDataList() {
      this.tableData = [];
      this.$http.get("/backend/lesson/" + this.lessonId + "/courses", {
        params: {
          word: this.searchValue,
          subject_id: this.subject_id,
        }
      }).then(res => {
        console.log(res.data);
        this.tableData = res.data.data;
      });
    },


    chooseTree(choose) {
      console.log(choose)
    },
    openAddItemModel() {
      this.oneItem = {
        name: '',
        lesson_id: 0,
        rank: 0,
        video_duration: 0,
        video_no: '',
        intro: '',
        video: '',
      };
      if (this.lessonDetails && this.lessonDetails.id > 0) {
        this.oneItem.lesson_id = this.lessonDetails.id
      }
      this.editModel = true;
    },
    openEditItemModel(row) {
      this.oneItem = {};
      this.oneItem = row;
      this.editModel = true;
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.detailsModel = true;
    },

    editItem() {
      if (this.oneItem.lesson_id <= 0) {
        this.oneItem.lesson_id = parseInt(this.lessonId)
      }

      this.oneItem.video_duration = parseFloat(this.oneItem.video_duration);
      this.oneItem.rank = parseFloat(this.oneItem.rank);
      this.$http.post('/backend/course', this.oneItem).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          });
          this.editModel = false;
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      })
    },
    delItem(row) {
      this.$http.delete('/backend/course/' + row.id).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      });
    },
  }
}
</script>
