<template>
  <div>
    <Row>
      <h2>课程管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Row>
          <subject-tree v-model="subject_id" @change="chooseTree" style="width: 400px;margin-right: 10px"></subject-tree>
          <Input v-model="searchValue" clearable placeholder="请输入课程名称" :style="{marginRight:'5px',width:'300px'}"></Input>
          <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
          <Divider type="vertical"/>
          <Button type="primary" @click="openAddItemModel">新增课程</Button>
        </Row>
      </Col>
    </Row>

    <br>


    <Row>
      <Table :columns="tableColumns" :data="tableData" border size="small"></Table>
    </Row>

    <Modal title="新增编辑课程" v-model="editModel" width="600">
      <Form :model="oneItem" :label-width="100">
        <FormItem label="课程类型">
          <RadioGroup v-model="oneItem.category">
            <Radio :label="1">理论学习</Radio>
            <Radio :label="2">实操视频</Radio>
          </RadioGroup>
        </FormItem>

        <FormItem label="所属科目">
          <subject-tree v-model="oneItem.subject_id" @change="chooseTree"
                        style="width: 400px;margin-right: 10px"></subject-tree>
        </FormItem>

        <FormItem label="课程名称">
          <Input v-model="oneItem.name"></Input>
        </FormItem>


        <FormItem label="排序(值越大越靠前)">
          <Input type="number" v-model="oneItem.rank"></Input>
        </FormItem>
      </Form>
      <div slot="footer" class="dialog-footer">
        <Button @click="editModel = false">取 消</Button>
        <Button type="primary" @click="editItem">确 定</Button>
      </div>
    </Modal>


  </div>
</template>

<script>
import SubjectTree from "@/components/subject-tree.vue"

export default {
  components: {
    SubjectTree
  },
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      tableData: [],
      tableColumns: [
        {title: 'id', width: 100, key: 'id'},
        {
          title: '课程名称', key: 'name', render: (h, params) => {
            return h('router-link', {
              props: {to: '/lessons/details?id=' + params.row.id}
            }, params.row.name);
          }
        },
        {
          title: '所属科目', key: 'subject_name', render: (h, params) => {
            return h('span', params.row.subject.full_name);
          }
        },
        {
          title: '课程类型', width: 100, key: 'category', render: (h, params) => {
            if (params.row.category === 2) {
              return h('span', "实操视频");
            } else {
              return h('span', "理论学习");
            }
          }
        },
        {title: '课时数', width: 80, key: 'courses_num'},
        {title: '排序', width: 80, key: 'rank'},
        {
          title: '操作', width: 140, key: 'action', align: 'center', render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.openEditItemModel(params.row)
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delItem(params.row)
                  }
                }
              }, '删除')
            ]);
          }
        }
      ],

      subject_id: 0,
      searchValue: '', // 搜索值

      oneItem: {
        name: '',
        intro: '',
        subject_id: 0,
        category: 1,
        rank: 0,
      },
      editModel: false, // 新增编辑
      detailsModel: false, // 详情弹窗
    }
  },
  created() {
    this.getDataList();
  },

  methods: {
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },
    getDataList() {
      this.tableData = [];
      this.$http.get("/backend/lessons", {
        params: {
          word: this.searchValue,
          subject_id: this.subject_id,
        }
      }).then(res => {
        console.log(res.data);
        this.tableData = res.data.data;
      });
    },

    chooseTree(choose) {
      console.log(choose)
      // this.subject_id = choose
    },
    openAddItemModel(row) {
      console.log(this.subject_id)
      let pid = 0;
      if (undefined !== row) {
        pid = row.id
      }
      this.oneItem = {
        name: '',
        pid: pid,

        category: 1,
        rank: 0,
        video_duration: 0,
        video_no: '',
        intro: '',
        video: '',
        subject_id: this.subject_id
      };
      this.editModel = true;
    },
    openEditItemModel(row) {
      this.oneItem = {};
      this.oneItem = row;
      this.editModel = true;
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.detailsModel = true;
    },

    editItem() {
      this.oneItem.video_duration = parseFloat(this.oneItem.video_duration);
      this.oneItem.rank = parseFloat(this.oneItem.rank);
      this.$http.post('/backend/lesson', this.oneItem).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          });
          this.editModel = false;
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      })
    },
    delItem(row) {
      this.$http.delete('/backend/lesson/' + row.id).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      });
    },
  }
}
</script>
