<template>
    <div>
      <Row><h2>题目管理</h2></Row>
      <br>

      <Row>
        <Col span="24">
          <Row>

          <Button type="primary" @click="openImportTopicModel()" :style="{marginRight:'5px',width:'80px'}">导入题目</Button>

          <subject-tree v-model="searchSubjectId" @change="chooseTree" :style="{marginRight:'5px',width:'300px'}"></subject-tree>

          <Select v-model="searchType" placeholder="题目类型" :style="{marginRight:'5px',width:'200px'}">
            <Option label="全部" value="0"></Option>
            <Option label="单选题" value="1"></Option>
            <Option label="判断题" value="2"></Option>
            <Option label="多选题" value="3"></Option>
          </Select>

          <Input v-model="searchValue" clearable placeholder="请输入内容" :style="{marginRight:'5px',width:'200px'}"></Input>
          <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
          </Row>
        </Col>
      </Row>

        <br>

        <Row>
            <el-table :data="tableData" :cell-style="cellStyle" border size="small">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div label-position="left" class="demo-table-expand">
                                <div>
                                    <span style="font-weight: 900">题干:</span>
                                    <span>{{ props.row.content }}</span>
                                </div>
                                <li v-for="option in props.row.options" :key="option.id">{{option.content}}</li>
                                <el-tag size="mini">答案:{{ props.row.answer }}</el-tag>

                                <div size="mini">解析:{{ props.row.analysis }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="subject.name" label="科目" width="180"></el-table-column>
                <el-table-column label="类型" :formatter="tableFormatType" width="80"></el-table-column>
                <el-table-column prop="content" label="题目"></el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
<!--                        <Button @click="handleClick(scope.row)" type="text" size="small">查看</Button>-->
<!--                        <Button type="text" size="small">编辑</Button>-->
                        <Button @click="delItem(scope.row)" type="error" size="small">删除</Button>
                    </template>
                </el-table-column>
            </el-table>
        </Row>

        <Row>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <el-pagination
                            @size-change="handlePageSizeChange"
                            @current-change="handlePageChange"
                            :current-page="nowPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal">
                    </el-pagination>
                </div>
            </div>
        </Row>

        <Modal title="新增编辑科目" v-model="importTopicModel">
            <Form :model="oneItem">
                <FormItem label="选择科目">
                    <subject-tree v-model="uploadData.subject_id" @change="chooseTree"></subject-tree>
                </FormItem>

                <FormItem label="题库文件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            :data="uploadData"
                            drag
                            action="/api/backend/topics/import"
                            :limit="1"
                            :on-success="handleUploadSuccess"
                            :on-error="handleUploadError"
                            :show-file-list="true"
                            :auto-upload="false">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </FormItem>
            </Form>

            <div slot="footer" class="dialog-footer">
                <Button @click="importTopicModel = false">取 消</Button>
                <Button type="primary" @click="importTopic">确 定</Button>
            </div>
        </Modal>


    </div>
</template>

<script>
    import SubjectTree from "@/components/subject-tree.vue"
    export default {
        data() {
            return {
                nowPage: 1, // 当前页
                pageTotal: 0, // 总页数
                pageSize: 10, // 每页数量

                tableData: [],

                searchValue: '', // 搜索值
                searchType: '', // 搜索值
                searchSubjectId:'',

                oneItem: {},
                editModel: false, // 新增编辑
                detailsModel: false, // 详情弹窗

                importTopicModel:false,
                uploadData:{
                    subject_id:0
                },
            }
        },
        components:{
            SubjectTree
        },
        created() {
            this.getDataList();
        },
        methods: {
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.getDataList()
            },
            handlePageChange(page) {
                this.nowPage = page;
                this.getDataList()
            },
            getDataList() {
                this.tableData = [];
                this.$http.get("/backend/topics", {
                    params: {
                        page: this.nowPage,
                        pre_page: this.pageSize,
                        word: this.searchValue,
                        type: this.searchType,
                        subject_id:this.searchSubjectId
                    }
                }).then(res => {
                    console.log(res.data);
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.meta.total;
                });
            },

            tableFormatType(row) {
                switch (row.type) {
                    case 1:
                        return '单选题';
                    case 2:
                        return '判断题';
                    case 3:
                        return '多选题';
                    default:
                        return '未知';
                }
            },

            openAddItemModel() {
                this.oneItem = {
                    name: '',
                    pid: 0,
                };
                this.editModel = true;
            },
            openEditItemModel(index) {
                this.oneItem = {};
                this.oneItem = this.tableData[index];
                this.editModel = true;
            },
            openDetailsModel(index) {
                this.oneItem = {};
                this.oneItem = this.tableData[index];
                this.detailsModel = true;
            },

            chooseTree(choose){
                console.log(choose)
            },
            openImportTopicModel(){
                this.importTopicModel = true
            },
            importTopic() {
                if (this.uploadData.subject_id === 0){
                    this.$notify.error({
                        title: '错误',
                        message: "请选择要导入的科目"
                    });
                    return
                }
                if (this.$refs.upload.uploadFiles.length<=0){
                    this.$notify.error({
                        title: '错误',
                        message: "请选择要上传的文件"
                    });
                    return;
                }
                this.$refs.upload.submit();
            },
            handleUploadSuccess(response, file, fileList){
                console.log("handleUploadSuccess")
                console.log(response)
                console.log(file)
                console.log(fileList)
                if (200 === response.code) {
                    this.$notify({
                        title: '成功',
                        message: '已创建导入任务，正在为您导入，请稍后查看导入结果',
                        type: 'success'
                    });
                    this.importTopicModel =false
                    this.$refs.upload.uploadFiles = []
                    this.uploadData.subject_id = 0
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: response.msg
                    });
                }

            },
            handleUploadError(err, file, fileList){
                console.log("handleUploadError")
                console.log(err)
                console.log(file)
                console.log(fileList)
                this.$notify.error({
                    title: '错误',
                    message: err
                });

            },

            delItem(row) {
                this.$http.delete('/backend/topic/' + row.id).then(resData => {
                    if (200 === resData.data.code) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success'
                        });
                        this.getDataList();
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: resData.data.msg
                        });
                    }
                });
            },

        }
    }
</script>
