<template>
    <div>
        <div slot="header" class="main-page-title"><span>{{bj.name}}-学员管理</span>-<Button type="text" @click="goBack">返回</Button></div>

        <Row :gutter="20">
            <Col :span="10">
                <Button type="primary" @click="toExport">导出学员统计表</Button>
            </Col>

            <Col :span="3">
                <Button type="primary" icon="el-icon-search" size="small" circle @click="getDataList"></Button>
            </Col>
        </Row>
        <br>

        <Row>
            <el-table :data="tableData" :cell-style="cellStyle" border size="small">
                <el-table-column prop="id" label="ID" width="66"></el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="手机号"></el-table-column>
                <el-table-column prop="id_card" label="身份证号"></el-table-column>
                <el-table-column prop="work_unit" label="工作单位"></el-table-column>
                <el-table-column prop="join_at" label="加入时间"></el-table-column>
                <el-table-column prop="subject_name" label="当前绑定科目">
                    <template slot-scope="scope">
                        <div v-if="scope.row.subject_id>0"> {{ scope.row.subject_name }}({{ scope.row.subject_id }})
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <Button @click="delItem(scope.row)" type="danger" size="mini">移除班级</Button>
                    </template>
                </el-table-column>
            </el-table>
        </Row>





    </div>
</template>

<script>
export default {
    data() {
        return {
            nowPage: 1, // 当前页
            pageTotal: 0, // 总页数
            pageSize: 10, // 每页数量

            classId:0,
            bj:{
              name:""
            },
            tableData: [],

            searchValue: '', // 搜索值

            oneItem: {},
            editModel: false, // 新增编辑
            detailsModel: false, // 详情弹窗

            UploadIdCardAvatarModel: false, // 上传证件照
        }
    },

    created() {
        this.classId=this.$route.params.id
        if (undefined === this.classId || "" === this.classId) {
            this.$router.push("/class")
        }
        this.bj=this.$route.params

        this.getDataList();
    },
    methods: {
        goBack() {
            this.$router.back();
        },
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.getDataList()
        },
        handlePageChange(page) {
            this.nowPage = page;
            this.getDataList()
        },
        getDataList() {
            this.tableData = [];
            this.$http.get("/backend/class_students", {
                params: {
                    class_id: this.classId,
                }
            }).then(res => {
                console.log(res.data);
                this.tableData = res.data.data;
            });
        },


        handleUploadSuccess(response, file, fileList) {
            console.log("handleUploadSuccess")
            console.log(response)
            console.log(file)
            console.log(fileList)
            if (200 !== response.code) {
                this.$notify.error({
                    title: '错误',
                    message: response.msg
                });
            }
        },
        handleUploadError(err) {
            this.$notify.error({
                title: '上传失败',
                message: err
            });
        },
        toExport() {
            var export_filename = "学员统计表-" + Date.parse(new Date()) + ".xlsx"
            const xhr = new XMLHttpRequest();
            xhr.open('get', "/api/backend/class_students?export=1&class_id="+this.classId);
            xhr.setRequestHeader("Authorization", 'Bearer ' + window.localStorage.getItem('token'));
            xhr.responseType = 'blob';
            xhr.send();
            xhr.onload = function (res) {
                console.log(res)
                if (this.status === 200 || this.status === 304) {
                    // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
                    if ('msSaveOrOpenBlob' in navigator) {
                        navigator.msSaveOrOpenBlob(this.response, export_filename);
                        return;
                    }
                    // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
                    // const url = URL.createObjectURL(blob);
                    const url = URL.createObjectURL(this.response);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = export_filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
            };
        },


        openAddItemModel() {
            this.oneItem = {
                name: '',
                pid: 0,
            };
            this.editModel = true;
        },
        openEditItemModel(index) {
            this.oneItem = {};
            this.oneItem = this.tableData[index];
            this.editModel = true;
        },
        openDetailsModel(index) {
            this.oneItem = {};
            this.oneItem = this.tableData[index];
            this.detailsModel = true;
        },


        delItem(row) {
            this.$http.delete('/backend/class_students/'+this.classId+'/' + row.id).then(resData => {
                if (200 === resData.data.code) {
                    this.$notify({
                        title: '成功',
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: resData.data.msg
                    });
                }
            });
        },
        periodPrint(userId) {
            this.$router.push({path: '/period_print', query: {user_id: userId}});
        },

    }
}
</script>
