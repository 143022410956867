<template>
  <div>
    <Row><h2>班级管理</h2></Row>
    <br>
    <Row>
      <Col span="24">
        <Input v-model="searchValue" clearable placeholder="请输入班级名称"
               :style="{marginRight:'5px',width:'300px'}"></Input>
        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>

        <Divider type="vertical"/>
        <Button type="primary" @click="openAddItemModel">新增班级</Button>
      </Col>
    </Row>
    <br>


    <Row>
      <Table :loading="tableLoading" :columns="tableColumns" :data="tableData" border size="small"></Table>
    </Row>

    <Modal title="新增编辑班级" v-model="editModel" width="80%">
      <Form :model="oneItem" :label-width="100">
        <FormItem label="机构">
          <local-select style="width: 200px" v-model="oneItem.organ_id" type="organs"></local-select>
        </FormItem>

        <Row>
          <Col span="8">
            <FormItem label="班级名称">
              <Input v-model="oneItem.name"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="科目">
              <subject-tree v-model="oneItem.subject_id"></subject-tree>
            </FormItem>
          </Col>
        </Row>

        <FormItem label="班级时间">
          <DatePicker
              style="width: 400px"
              v-model="classTimes"
              type="datetimerange"
              placeholder="开始日期-结束日期"
              align="right">
          </DatePicker>
        </FormItem>

        <Row>
          <Col span="8">
            <FormItem label="课程验证方式">
              <Input value="验证码"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="验证次数">
              <Input-number v-model="oneItem.verify_times" :min="1" :max="4"></Input-number>
            </FormItem>
          </Col>
        </Row>

        <FormItem label="班主任">
          <local-select style="width: 200px" v-model="oneItem.teacher_id" type="user_teachers"></local-select>
        </FormItem>

        <FormItem label="班级课程">
          <el-transfer :list-style="{width: '600px'}" v-model="oneItem.lesson_ids"
                       :titles="['待选择', '已选择']"
                       :button-texts="['移除', '增加']"
                       :data="courses"></el-transfer>
        </FormItem>

      </Form>

      <div slot="footer" class="dialog-footer">
        <Button @click="editModel = false">取 消</Button>
        <Button type="primary" @click="editItem">确 定</Button>
      </div>
    </Modal>


  </div>
</template>

<script>
import LocalSelect from "@/components/local-select.vue"
import SubjectTree from "@/components/subject-tree.vue";
import moment from "moment";

export default {
  components: {
    SubjectTree,
    LocalSelect,
  },
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      tableData: [],
      tableColumns: [
        {title: 'id', width: 60, key: 'id'},
        {
          title: '班级名称', key: 'name', render: (h, params) => {
            return h('router-link', {
              props: {to: '/class/details?id=' + params.row.id}
            }, params.row.name);
          }
        },
        {title: '班级编号', key: 'number'},
        {
          title: '所属科目', key: 'subject_name', render: (h, params) => {
            return h('span', params.row.subject.full_name);
          }
        },

        {
          title: '班主任', key: 'teacher.name', render: (h, params) => {
            return h('span', params.row.teacher.name);
          }
        },

        {
          title: '开班时间', width: 110, key: "start_time", render: (h, params) => {
            return h('span', moment(params.row.start_time).format('YYYY-MM-DD'));
          }
        },
        {
          title: '结束时间', width: 110, key: 'expired_time', render: (h, params) => {
            return h('span', moment(params.row.expired_time).format('YYYY-MM-DD'));
          }
        },

        {title: '操作', width: 140, key: 'action', align: 'center', render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.openEditItemModel(params.row)
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delItem(params.row)
                  }
                }
              }, '删除')
            ]);
          }}
      ],
      tableLoading: true,
      searchValue: '', // 搜索值
      classTimes: [],//日期
      courses: [],
      oneItem: {
        name: '',
        verify_times: 1,
        start_time: '',
        expired_time: '',
        subject_id: 0,
        organ_id: 0,
        teacher_id: 0,
        lesson_ids: [],
      },

      import_class_id: 0,
      import_id_cards: "",

      editModel: false, // 新增编辑
      importUserModel: false, // 新增编辑

      detailsModel: false, // 详情弹窗
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },
    getDataList() {
      this.tableLoading = true
      this.tableData = [];
      this.$http.get("/backend/class", {
        params: {
          word: this.searchValue,
        }
      }).then(res => {
        this.tableLoading = false
        this.tableData = res.data.data;
      });
    },
    getCourses() {
      this.courses = [];
      this.$http.get("/backend/lessons/transfer", {
        params: {
          subject_id: this.oneItem.subject_id,
        }
      }).then(res => {
        this.courses = res.data.data;
      });
    },


    openAddItemModel() {
      this.oneItem = {
        name: '',
        verify_times: 1,
        start_time: '',
        expired_time: '',
        subject_id: 0,
        organ_id: 0,
        teacher_id: 0,
        lesson_ids: [],
      };
      this.getCourses();
      this.editModel = true;
    },
    openEditItemModel(row) {
      this.oneItem = {
        name: '',
        verify_times: '',
        start_time: '',
        expired_time: '',
        subject_id: 0,
        organ_id: 0,
        teacher_id: 0,
        lesson_ids: [],
      };
      this.oneItem = row;
      this.getCourses();
      this.oneItem.lesson_ids = row.lesson_ids
      this.classTimes = [this.oneItem.start_time, this.oneItem.expired_time]
      this.editModel = true;
    },
    openDetailsModel(row) {
      this.oneItem = row;
      this.detailsModel = true;
    },
    openImportUserModel(row) {
      this.import_class_id = row.id;
      this.import_id_cards = "";
      this.importUserModel = true;
    },

    editItem() {
      if (null !== this.classTimes && 2 === this.classTimes.length) {
        this.oneItem.start_time = this.classTimes[0]
        this.oneItem.expired_time = this.classTimes[1]
      } else {
        this.$notify.error({
          title: '错误',
          message: "请选择时间"
        });
        return
      }

      this.$http.post('/backend/class', this.oneItem).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          });
          this.editModel = false;
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      })
    },
    delItem(row) {
      this.$http.delete('/backend/class/' + row.id).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      });
    },

    importUser() {
      this.$http.post('/backend/class/import_user', {
        class_id: this.import_class_id,
        id_cards: this.import_id_cards
      }).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          });
          this.importUserModel = false;
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      })
    },

    toUserList(row) {
      // this.$router.push('/class/students?class_id='+row.id);
      this.$router.push({
        name: "学员管理", //这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: row
      });
    }


  }
}
</script>
