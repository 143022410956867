export default {
    "index": 0,
    "name": 1,
    "height": 210,
    "width": 297,
    "paperHeader": 49.5,
    "paperFooter": 551.5151515151515,
    "printElements": [
        {
            "options": {
                "zIndex": 1,
                "left": 0,
                "top": 0,
                "height": 591,
                "width": 840,
                "src": 'https://exam-1301658856.cos.ap-chengdu.myqcloud.com/common/zhengshu2.1.png',
                "fit": 'fill',
                "right": 840.75,
                "bottom": 591,
                "vCenter": 420.75,
                "hCenter": 295.5
            },
            "printElementType": {
                "title": '背景',
                "type": 'image'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 102.5,
                "top": 222.5,
                "height": 112.5,
                "width": 654,
                "title": ' 学员: 李思龙， 身份证号: 410928199803276012 于 2023年12月24日至2024年01月07日 在安全生产在线培训系统参加 新训-危险化学品从业人员 课程在线培训，已完成72学时学习，考核合格。',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 20,
                "lineHeight": 30,
                "longTextIndent": 33.75,
                "right": 755.748046875,
                "bottom": 334.248046875,
                "vCenter": 428.748046875,
                "hCenter": 277.998046875,
                "field": 'content',
                "testData": '学员: 李思龙， 身份证号: 410928199803276012 于 2023年12月24日至2024年01月07日 在安全生产在线培训系统参加 新训-危险化学品从业人员 课程在线培训，已完成72学时学习，考核合格。',
                "hideTitle": true
            },
            "printElementType": {
                "title": '长文',
                "type": 'longText'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 257.5,
                "top": 150,
                "height": 34.5,
                "width": 115.5,
                "title": '证书编号：',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 22,
                "textAlign": 'center',
                "textContentVerticalAlign": 'middle',
                "qrCodeLevel": 0,
                "right": 498,
                "bottom": 160.998046875,
                "vCenter": 339,
                "hCenter": 156.123046875,
                "fontWeight": 'bold'
            },
            "printElementType": {
                "title": '文本',
                "type": 'text'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 335,
                "top": 85,
                "height": 37.5,
                "width": 211.5,
                "title": '培训合格证书',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 28,
                "fontWeight": 'bold',
                "letterSpacing": 3,
                "color": '#95c459',
                "textAlign": 'center',
                "textContentVerticalAlign": 'middle',
                "qrCodeLevel": 0,
                "right": 546.498046875,
                "bottom": 122.49609375,
                "vCenter": 440.748046875,
                "hCenter": 103.74609375
            },
            "printElementType": {
                "title": '文本',
                "type": 'text'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 335,
                "top": 407.5,
                "height": 42,
                "width": 91.5,
                "title": '培训机构：',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 18,
                "textContentVerticalAlign": 'middle',
                "qrCodeLevel": 0,
                "right": 738.99609375,
                "bottom": 450.498046875,
                "vCenter": 543.24609375,
                "hCenter": 429.498046875
            },
            "printElementType": {
                "title": '文本',
                "type": 'text'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 335,
                "top": 455,
                "height": 36,
                "width": 91.5,
                "title": '发证日期：',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 18,
                "textContentVerticalAlign": 'middle',
                "qrCodeLevel": 0,
                "right": 610.998046875,
                "bottom": 490.998046875,
                "vCenter": 480.498046875,
                "hCenter": 472.998046875
            },
            "printElementType": {
                "title": '文本',
                "type": 'text'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 117.5,
                "top": 380,
                "height": 102,
                "width": 102,
                "title": '二维码',
                "qrcodeType": 'qrcode',
                "testData": 'qrcode',
                "field": 'qr_code',
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "hideTitle": true,
                "right": 219.498046875,
                "bottom": 481.998046875,
                "vCenter": 168.498046875,
                "hCenter": 430.998046875
            },
            "printElementType": {
                "title": '二维码',
                "type": 'qrcode'
            }
        },
        {
            "options": {
                "zIndex": 80,
                "left": 447.5,
                "top": 370,
                "height": 150,
                "width": 150,
                "src": 'https://exam-1301658856.cos.ap-chengdu.myqcloud.com/common/zhang.1.png',
                "fit": 'contain',
                "right": 358.998046875,
                "bottom": 510,
                "vCenter": 283.998046875,
                "hCenter": 435
            },
            "printElementType": {
                "title": '章',
                "type": 'image'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 357.5,
                "top": 150,
                "height": 34.5,
                "width": 288,
                "title": '证书编号内容',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 22,
                "textAlign": 'center',
                "textContentVerticalAlign": 'middle',
                "qrCodeLevel": 0,
                "right": 619.998046875,
                "bottom": 183.75,
                "vCenter": 473.748046875,
                "hCenter": 166.5,
                "field": 'number',
                "testData": '1743955216413065217',
                "hideTitle": true,
                "fontWeight": 'bold'
            },
            "printElementType": {
                "title": '文本',
                "type": 'text'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 417.5,
                "top": 455,
                "height": 36,
                "width": 160.5,
                "title": '发证日期',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 18,
                "textContentVerticalAlign": 'middle',
                "qrCodeLevel": 0,
                "right": 704.748046875,
                "bottom": 490.998046875,
                "vCenter": 574.248046875,
                "hCenter": 472.998046875,
                "field": 'completion_time',
                "testData": '2024年01月07日',
                "hideTitle": true
            },
            "printElementType": {
                "title": '文本',
                "type": 'text'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 417.5,
                "top": 407.5,
                "height": 42,
                "width": 351,
                "title": '自贡市长安安全生产培训服务有限责任公司',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 18,
                "textContentVerticalAlign": 'middle',
                "qrCodeLevel": 0,
                "right": 832.248046875,
                "bottom": 450.498046875,
                "vCenter": 636.498046875,
                "hCenter": 429.498046875
            },
            "printElementType": {
                "title": '文本',
                "type": 'text'
            }
        },
        {
            "options": {
                "zIndex": 9,
                "left": 132.5,
                "top": 342.5,
                "height": 22.5,
                "width": 120,
                "title": '特颁此证',
                "coordinateSync": false,
                "widthHeightSync": false,
                "fontSize": 20,
                "qrCodeLevel": 0,
                "right": 252.498046875,
                "bottom": 365.25,
                "vCenter": 192.498046875,
                "hCenter": 354
            },
            "printElementType": {
                "title": '文本',
                "type": 'text'
            }
        }
    ],
    "paperNumberLeft": 565.5,
    "paperNumberTop": 573,
    "paperNumberDisabled": true,
    "paperNumberContinue": true,
    "rotate": true,
    "watermarkOptions": {
        "content": '',
        "rotate": 25,
        "timestamp": true,
        "format": 'YYYY-MM-DD HH:mm',
        "fillStyle": 'rgba(184, 184, 184, 0.3)',
        "fontSize": '14px',
        "width": 200,
        "height": 200
    },
    "panelLayoutOptions": {
        "layoutType": 'column',
        "layoutRowGap": 0,
        "layoutColumnGap": 0
    }
}