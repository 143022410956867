export default {
    "panels": [
        {
            "index": 0,
            "name": 1,
            "height": 297,
            "width": 210,
            "paperHeader": 49.5,
            "paperFooter": 780,
            "printElements": [
                {
                    "options": {
                        "left": 167.5,
                        "top": 72.5,
                        "height": 28.5,
                        "width": 225,
                        "title": '安全生产远程培训系统培训学时证明',
                        "coordinateSync": false,
                        "widthHeightSync": false,
                        "fontSize": 12,
                        "fontWeight": 'bold',
                        "textAlign": 'center',
                        "textContentVerticalAlign": 'middle',
                        "qrCodeLevel": 0,
                        "right": 393,
                        "bottom": 99.99609375,
                        "vCenter": 280.5,
                        "hCenter": 85.74609375
                    },
                    "printElementType": {
                        "title": '文本',
                        "type": 'text'
                    }
                },
                {
                    "options": {
                        "left": 347.5,
                        "top": 702.5,
                        "height": 28.5,
                        "width": 201,
                        "title": '网络培训机构（盖章）\n',
                        "coordinateSync": false,
                        "widthHeightSync": false,
                        "fontSize": 12,
                        "fontWeight": 'bold',
                        "textAlign": 'center',
                        "textContentVerticalAlign": 'middle',
                        "qrCodeLevel": 0,
                        "right": 549,
                        "bottom": 730.74609375,
                        "vCenter": 448.5,
                        "hCenter": 716.49609375
                    },
                    "printElementType": {
                        "title": '文本',
                        "type": 'text'
                    }
                },
                {
                    "options": {
                        "left": 422.5,
                        "top": 675,
                        "height": 82.5,
                        "width": 82.5,
                        "right": 504.24609375,
                        "bottom": 756.24609375,
                        "vCenter": 462.99609375,
                        "hCenter": 714.99609375,
                        "src": 'https://exam-1301658856.cos.ap-chengdu.myqcloud.com/common/zhang.1.png',
                        "fit": ''
                    },
                    "printElementType": {
                        "title": '图片',
                        "type": 'image'
                    }
                },
                {
                    "options": {
                        "left": 35,
                        "top": 130,
                        "height": 537,
                        "width": 525,
                        "right": 584.998046875,
                        "bottom": 165.24609375,
                        "vCenter": 309.998046875,
                        "hCenter": 147.24609375,
                        "field": 'table',
                        "coordinateSync": false,
                        "widthHeightSync": false,
                        "columns": [
                            [
                                {
                                    "width": 29.305796059709504,
                                    "title": '序号',
                                    "field": 'index',
                                    "checked": true,
                                    "columnId": 'index',
                                    "fixed": false,
                                    "rowspan": 1,
                                    "colspan": 1
                                },
                                {
                                    "width": 92.28145515061861,
                                    "title": '姓名',
                                    "field": 'name',
                                    "checked": true,
                                    "columnId": 'name',
                                    "fixed": false,
                                    "rowspan": 1,
                                    "colspan": 1
                                },
                                {
                                    "width": 118.00299092253897,
                                    "title": '身份证号码',
                                    "field": 'id_card',
                                    "checked": true,
                                    "columnId": 'id_card',
                                    "fixed": false,
                                    "rowspan": 1,
                                    "colspan": 1
                                },
                                {
                                    "width": 149.4446215034965,
                                    "title": '培训内容',
                                    "field": 'content',
                                    "checked": true,
                                    "columnId": 'content',
                                    "fixed": false,
                                    "rowspan": 1,
                                    "colspan": 1
                                },
                                {
                                    "width": 62.017803321678336,
                                    "title": '培训总学时',
                                    "field": 'num',
                                    "checked": true,
                                    "columnId": 'num',
                                    "fixed": false,
                                    "rowspan": 1,
                                    "colspan": 1
                                },
                                {
                                    "width": 73.94733304195803,
                                    "title": '培训完成时间',
                                    "field": 'completion_time',
                                    "checked": true,
                                    "columnId": 'completion_time',
                                    "fixed": false,
                                    "rowspan": 1,
                                    "colspan": 1
                                },
                                {
                                    "width": 100,
                                    "title": '',
                                    "field": '',
                                    "checked": false,
                                    "columnId": '',
                                    "fixed": false,
                                    "rowspan": 1,
                                    "colspan": 1
                                },
                                {
                                    "width": 100,
                                    "title": '',
                                    "field": '',
                                    "checked": false,
                                    "columnId": '',
                                    "fixed": false,
                                    "rowspan": 1,
                                    "colspan": 1
                                }
                            ]
                        ]
                    },
                    "printElementType": {
                        "title": '空白表格',
                        "type": 'table',
                        "editable": true,
                        "columnDisplayEditable": true,
                        "columnDisplayIndexEditable": true,
                        "columnTitleEditable": true,
                        "columnResizable": true,
                        "columnAlignEditable": true,
                        "isEnableEditField": true,
                        "isEnableContextMenu": true,
                        "isEnableInsertRow": true,
                        "isEnableDeleteRow": true,
                        "isEnableInsertColumn": true,
                        "isEnableDeleteColumn": true,
                        "isEnableMergeCell": true
                    }
                }
            ],
            "paperNumberLeft": 565.5,
            "paperNumberTop": 819,
            "paperNumberDisabled": true,
            "paperNumberContinue": true,
            "watermarkOptions": {
                "content": '',
                "rotate": 25,
                "timestamp": true,
                "format": 'YYYY-MM-DD HH:mm',
                "fillStyle": 'rgba(184, 184, 184, 0.3)',
                "fontSize": '14px',
                "width": 200,
                "height": 200
            },
            "panelLayoutOptions": {
                "layoutType": 'column',
                "layoutRowGap": 0,
                "layoutColumnGap": 0
            }
        }
    ]
}