<template>
  <div>
    <Row><h2>导入记录</h2></Row>
    <br>
    <Row>
      <Col span="24">
        <Input v-model="searchValue" clearable placeholder="" :style="{marginRight:'5px',width:'300px'}"></Input>
        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
      </Col>
    </Row>
    <br>

    <Row>
      <el-table :data="tableData" :cell-style="cellStyle" border size="small">
        <el-table-column prop="file_name" label="文件名称"></el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="total" label="总数" width="50"></el-table-column>
        <el-table-column prop="succeed_num" label="成功数" width="60"></el-table-column>
        <el-table-column prop="failure_num" label="失败数" width="60"></el-table-column>
        <el-table-column prop="execute_at" label="执行时间"></el-table-column>
        <el-table-column prop="finish_at" label="完成时间"></el-table-column>
        <el-table-column prop="status" label="当前状态"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <Button @click="openDetailsModel(scope.row)" type="primary" size="small">详情</Button>
          </template>
        </el-table-column>
      </el-table>
    </Row>

    <Row>
      <div style="margin: 10px;overflow: hidden">
        <div style="float: right;">
          <el-pagination
              @size-change="handlePageSizeChange"
              @current-change="handlePageChange"
              :current-page="nowPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal">
          </el-pagination>
        </div>
      </div>
    </Row>


    <Modal title="错误信息" v-model="detailsModel" width="30%">
      <span>{{ oneItem.information }}</span>
    </Modal>


  </div>
</template>

<script>
export default {
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      tableData: [],

      searchValue: '', // 搜索值
      searchType: '', // 搜索值
      searchSubjectId: '',

      oneItem: {},
      editModel: false, // 新增编辑
      detailsModel: false, // 详情弹窗

      importTopicModel: false,
    }
  },

  created() {
    this.getDataList();
  },
  methods: {
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },
    getDataList() {
      this.tableData = [];
      this.$http.get("/backend/imports", {
        params: {
          page: this.nowPage,
          pre_page: this.pageSize,
          word: this.searchValue,
        }
      }).then(res => {
        console.log(res.data);
        this.tableData = res.data.data;
        this.pageTotal = res.data.meta.total;
      });
    },


    openAddItemModel() {
      this.oneItem = {
        name: '',
        pid: 0,
      };
      this.editModel = true;
    },
    openEditItemModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.editModel = true;
    },
    openDetailsModel(row) {
      this.oneItem = {};
      this.oneItem = row;
      this.detailsModel = true;
    },

    chooseTree(choose) {
      console.log(choose)
      this.searchSubjectId = choose
      this.uploadData.subject_id = choose
    },
    openImportTopicModel() {
      this.importTopicModel = true
    },

  }
}
</script>
