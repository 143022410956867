<template>
  <div>
    <div class="print-btn-div">
      <Button type="primary" class="print-btn" @click="goBack">返回</Button>
      <Button type="primary" class="print-btn" icon="el-icon-printer" @click="print">打印</Button>
      <Button type="primary" class="print-btn" icon="el-icon-printer" @click="toPdf">下载为PDF</Button>
    </div>
    <div class="print-page">
      <div id="preview_content"></div>
    </div>


  </div>

</template>

<script>
import Vue from 'vue';
import Print from '../plugs/print';
import printTeml from '../print/cert'
import moment from "moment";

Vue.use(Print);

export default {
  data() {
    return {
      hiprintTemplate: null,
      printData: {},

      classId: 0,
      classDetails: {},

      classStudents: [],
      classLessons: [],
      classSituation: [],


      user_id: 0,
      tableData: [],
      user: {},
      res: {}
    }
  },

  created() {
    this.classId = this.$route.params.id ? this.$route.params.id : this.$route.query.id
    if (undefined === this.classId || "" === this.classId) {
      this.$router.push("/class")
    }
    this.getClassDetails();
    this.getClassSituation();
  },
  methods: {
    getClassDetails() {
      this.classDetails = {};
      this.$http.get("/backend/class/" + this.classId).then(res => {
        this.classDetails = res.data.data;
      });
    },
    getClassSituation() {
      this.tableData = [];
      var items = {};
      this.$http.get("/backend/class/" + this.classId + "/situation").then(res => {
        this.classSituation = res.data.data;
        this.$pluginName.init();
        this.hiprintTemplate = new this.$pluginName.PrintTemplate();



        for (let i = 0; i < res.data.data.length; i++) {
          items[this.classSituation[i].cert_number + "_number"] = this.classSituation[i].cert_number
          items[this.classSituation[i].cert_number + "_id_card"] = this.classSituation[i].user.id_card
          items[this.classSituation[i].cert_number + "_content"] = `学员: ${this.classSituation[i].user.name}， 身份证号: ${this.classSituation[i].user.id_card} 于 ${moment(this.classSituation[i].start_time).format('YYYY年MM月DD日')}至${moment(this.classSituation[i].complete_time).format('YYYY年MM月DD日')} 在安全生产在线培训系统参加 ${this.classSituation[i].class.name} 课程在线培训，已完成${this.classSituation[i].course_num}学时学习，考核合格。`
          items[this.classSituation[i].cert_number + "_qr_code"] = this.classSituation[i].cert_number
          items[this.classSituation[i].cert_number + "_completion_time"] = moment(this.classSituation[i].complete_time).format('YYYY-MM-DD')

          //深度复制
          let itemTemplate = JSON.parse(JSON.stringify(printTeml));
          itemTemplate.index = i
          itemTemplate.name = this.classSituation[i].cert_number

          for (let j = 0; j < itemTemplate.printElements.length; j++) {
            switch (itemTemplate.printElements[j].options.field) {
              case "number":
              case "id_card":
              case "content":
              case "qr_code":
              case "completion_time":
                itemTemplate.printElements[j].options.field = this.classSituation[i].cert_number + "_" + itemTemplate.printElements[j].options.field
            }
          }
          this.hiprintTemplate.addPrintPanel(itemTemplate)
        }


        this.printData = items
        $('#preview_content').html(this.hiprintTemplate.getHtml(this.printData));
      });
    },
    preview() {

    },


    goBack() {
      this.$router.back();
    },
    print() {
      this.hiprintTemplate.print(this.printData, {}, {
        callback: () => {
          // this.waitShowPrinter = false
        }
      })
    },
    toPdf() {
      this.hiprintTemplate.toPdf(this.printData, '安全生产远程培训系统培训学时证明');
    },
  }
}
</script>


<style>
/*整个页面的最外层*/
.print-page {
  padding: 0 50px 50px 50px;
  width: 1200px;
  min-height: 900px;
  border: 1px solid #000;
  background-color: #ffffff
}


/*打印按钮的div*/
.print-btn-div {
  width: 650px;
  margin: 0 auto;
  height: 50px;
}

/*打印按钮*/
.print-btn {
  float: right;
  margin-left: 10px;
}


</style>
