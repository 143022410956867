import Vue from 'vue'
import Router from 'vue-router'


import Lagout from './views/lagout.vue'

import login from './views/login.vue'
import index from './views/index.vue'
import role from './views/role.vue'
import subject from './views/subject.vue'
import topic from './views/topic.vue'
import Lessons from './views/lessons.vue'
import LessonsDetails from './views/lessons_details.vue'
import LessonsDetailsV2 from './views/lessons_details_v2.vue'

import users from './views/users.vue'
import learns from './views/learns.vue'
import orders from './views/orders.vue'
import cdKeys from './views/cdKeys.vue'
import imports from './views/imports.vue'
import vips from './views/vips.vue'
import organs from './views/organs.vue'
import periods from './views/periods.vue'
import periodPrint from './views/period_print.vue'
import registers from './views/registers.vue'
import registersInfo from './views/registers_info.vue'

import Teacher from './views/teacher.vue'
import Students from "./views/students.vue";
import Class from './views/class.vue'
import ClassDetails from "./views/class_details.vue";
import ClassPeriodPrint from "./views/class_period_print.vue";
import ClassPrintCert from "./views/class_print_cert.vue";

import Menus from "./views/menus.vue";


Vue.use(Router);
let routes = [
    // {path: '/', name: '首页', meta: {title: '首页'}, component: index},
    {path: '/index', name: '首页', component: index},
    {path: '/roles', name: '角色管理', component: role},
    {path: '/subjects', name: '科目管理', component: subject},
    {path: '/topics', name: '题目管理', component: topic},

    {path: '/lessons', name: '课程管理', component: Lessons},
    {path: '/lessons/details', name: '课时管理', component: LessonsDetails},

    {path: '/users', name: '用户管理', component: users},
    {path: '/learns', name: '学习记录', component: learns},
    {path: '/orders', name: '充值记录', component: orders},
    {path: '/cd_keys', name: '充值卡号', component: cdKeys},
    {path: '/vips', name: '充值科目', component: vips},
    {path: '/imports', name: '导入记录', component: imports},
    {path: '/organs', name: '合作伙伴', component: organs},
    {path: '/periods', name: '学时记录', component: periods},
    {path: '/period_print', name: '学时打印', component: periodPrint},
    {path: '/registers', name: '报名列表', component: registers},
    {path: '/registers_info', name: '登记列表', component: registersInfo},
    {path: '/teacher', name: '班主任管理', component: Teacher},

    {path: '/class', name: '班级管理', component: Class},
    {path: '/class/students', name: '学员管理', component: Students},
    {path: '/class/details', name: '班级详情', component: ClassDetails},
    {path: '/class/print/period', name: '学时证明', component: ClassPeriodPrint},
    {path: '/class/print/cert', name: '学时证明', component: ClassPrintCert},

    {path: '/menus', name: '菜单管理', component: Menus},

    {path: '/courses', name: '课程管理', component: LessonsDetailsV2},
]


const router = new Router({
    mode: 'history',
    routes: [
        {path: '/login', component: login},
        // {path: '/period_print', component: periodPrint},
        {path: '/*', component: Lagout, children: routes}
    ]
});

router.beforeEach((to, from, next) => {
    if ("/login" !== to.path) {
        if (window.localStorage.getItem('token')) {
            next();
        } else {
            next({path: '/login'})
        }
    } else {
        next();
    }
})

export default router
