<template>
  <div>
    <Select v-model="choose" filterable placeholder="请选择" clearable @change="handleChange">
      <Option label="未选择" :value="0"></Option>
      <Option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </Option>
    </Select>

  </div>
</template>

<script>
export default {
  name: "my-select",
  props: ['type', 'custom', 'value'],
  model: {
    prop: 'value',
    event: 'input',
  },
  computed: {
    choose: {
      get() {
        return this.value
      },
      set(val) {
        // 调用父组件的customFunc方法，将变量放在第二个参数，即可改变父组件的变量
        this.$emit('input', val)
      }
    }
  },
  data() {
    return {
      options: [],
    }
  },

  created() {
    // console.log("local-select,value：",this.value)
    this.getOptions();
  },
  methods: {
    handleChange(choose) {
      this.$emit('input', choose)
    },
    getOptions() {
      this.options = [];
      this.$http.get("/backend/common/select?type=" + this.type).then(res => {
        console.log(res.data);
        this.options = res.data.data;
      });
    },

  }
}
</script>