<template>
    <div>

      <Row><h2>班主任管理</h2></Row>
      <br>

      <Row>
        <Col span="24">
          <Button type="primary" @click="openAddItemModel" style="margin-right: 5px">新建班主任</Button>

          <Input v-model="searchValue" clearable placeholder="请输入用户id/姓名/身份证号码 搜索"
                 :style="{marginRight:'5px',width:'300px'}"></Input>

          <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
        </Col>
      </Row>
      <br>


        <br>

        <Row>
            <el-table v-loading="tableLoading" :data="tableData" :cell-style="cellStyle" border size="small">
                <el-table-column prop="id" label="ID" width="66"></el-table-column>
                <el-table-column prop="avatar" label="机构" width="120">
                    <template slot-scope="scope">
                        <div v-if="scope.row.organ"> {{ scope.row.organ.name }}</div>
                    </template>
                </el-table-column>

                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="手机号"></el-table-column>

                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
<!--                        <Button @click="periodPrint(scope.row.id)" size="small">学时打印</Button>-->
                        <Button @click="openEditItemModel(scope.row)" size="small" style="margin-right: 5px">编辑</Button>
                        <Button @click="delItem(scope.row)" type="error" size="small">删除</Button>
                    </template>
                </el-table-column>
            </el-table>
        </Row>

        <Row>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <el-pagination
                            @size-change="handlePageSizeChange"
                            @current-change="handlePageChange"
                            :current-page="nowPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal">
                    </el-pagination>
                </div>
            </div>
        </Row>


        <Modal title="新增编辑班主任" v-model="editModel" width="65%">
            <Form :model="oneItem">
                <FormItem label="机构">
                    <local-select v-model="oneItem.organ_id" type="organs"></local-select>
                </FormItem>
                <FormItem label="姓名">
                    <Input v-model="oneItem.name"></Input>
                </FormItem>
                <FormItem label="手机号">
                    <Input v-model="oneItem.phone"></Input>
                </FormItem>
                <FormItem label="密码">
                    <Input v-model="oneItem.password" show-password></Input>
                </FormItem>
            </Form>

            <div slot="footer" class="dialog-footer">
                <Button @click="editModel = false">取 消</Button>
                <Button type="primary" @click="editItem">确 定</Button>
            </div>
        </Modal>

    </div>
</template>

<script>
import LocalSelect from "@/components/local-select.vue"

export default {
    components: {
        LocalSelect
    },
    data() {
        return {
            nowPage: 1, // 当前页
            pageTotal: 0, // 总页数
            pageSize: 10, // 每页数量

            tableData: [],
            tableLoading:true,

            searchValue: '', // 搜索值

            oneItem: {},
            editModel: false, // 新增编辑
            detailsModel: false, // 详情弹窗

            UploadIdCardAvatarModel: false, // 上传证件照
        }
    },

    created() {
        this.getDataList();
    },
    methods: {
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.getDataList()
        },
        handlePageChange(page) {
            this.nowPage = page;
            this.getDataList()
        },
        getDataList() {
            this.tableLoading=true;
            this.tableData = [];
            this.$http.get("/backend/users", {
                params: {
                    page: this.nowPage,
                    is_teacher: 1,
                    pre_page: this.pageSize,
                    word: this.searchValue,
                }
            }).then(res => {
                this.tableData = res.data.data;
                this.pageTotal = res.data.meta.total;
                this.tableLoading=false;
            });
        },


        openAddItemModel() {
            this.oneItem = {
                organ_id: 0,
                name: '',
                phone: "",
                password: '',
            };
            this.editModel = true;
        },
        openEditItemModel(row) {
            this.oneItem = {
                organ_id: 0,
                name: '',
                phone: "",
                password: '',
            };
            this.oneItem = row;
            this.editModel = true;
        },
        openDetailsModel(index) {
            this.oneItem = {};
            this.oneItem = this.tableData[index];
            this.detailsModel = true;
        },
        editItem() {
            this.$http.post('/backend/users/teachers', this.oneItem).then(resData => {
                if (200 === resData.data.code) {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.editModel = false;
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: resData.data.msg
                    });
                }
            })
        },


        delItem(row) {
            this.$http.delete('/backend/topic/' + row.id).then(resData => {
                if (200 === resData.data.code) {
                    this.$notify({
                        title: '成功',
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: resData.data.msg
                    });
                }
            });
        },
        periodPrint(userId) {
            this.$router.push({path: '/period_print', query: {user_id: userId}});
        },

    }
}
</script>
