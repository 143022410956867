<style scoped>
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.ivu-layout-sider {
  background: #fff;
  border-right: 0.5px solid #DCDEE2;
}

.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
}

.layout-menu-left {
  background: #fff;
  padding-top: 20px;
}

.layout-logo-left {
  width: 80%;
  height: 30px;
  background: #fff;
  border-radius: 3px;
  margin: 10px auto;
}

.menu-icon {
  transition: all .3s;
}

.rotate-icon {
  transform: rotate(-90deg);
}

.menu-item span {
  display: inline-block;
  overflow: hidden;
  max-width: 70px;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  transition: width .2s ease .2s;
}

.menu-item i {
  transform: translateX(0px);
  transition: font-size .2s ease, transform .2s ease;
  vertical-align: middle;
  font-size: 16px;
}

.collapsed-menu .ivu-menu-item {
  padding: 12px 12px;
}


.collapsed-menu span {
  width: 0px;
  transition: width .2s ease;
}

.collapsed-menu i {
  transform: translateX(5px);
  transition: font-size .2s ease .2s, transform .2s ease .2s;
  vertical-align: middle;
  font-size: 18px;
}
</style>
<template>
  <div class="layout">
    <Layout>
      <Sider ref="side1" hide-trigger collapsible :collapsed-width="70" v-model="isCollapsed">
        <Menu ref="layout_menus"
              :active-name="activeMenu"
              :style="{paddingTop:'50px'}"
              theme="light"
              width="auto"
              :class="menuitemClasses"
              @on-select="selectMenu">
          <Submenu :key="menu.id" v-for="menu in menuData" :name="menu.router" :index="menu.router">
            <template slot="title">
              <Icon :type="menu.ico?menu.ico:''"/>
              {{ menu.name }}
            </template>
            <MenuItem :key="menuItem.id" v-for="menuItem in menu.children" :name="menuItem.router" :index="menu.router">
              {{ menuItem.name }}
            </MenuItem>
          </Submenu>
        </Menu>
      </Sider>
      <Layout>
        <Header :style="{padding: 0}" class="layout-header-bar">

        </Header>
        <Content :style="{margin: '10px',padding:'15px', background: '#fff', minHeight: '100vh'}">
          <router-view></router-view>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCollapsed: false,
      menuData: [],
      activeMenu: '',
    }
  },
  computed: {
    rotateIcon() {
      return [
        'menu-icon',
        this.isCollapsed ? 'rotate-icon' : ''
      ];
    },
    menuitemClasses() {
      return [
        'menu-item',
        this.isCollapsed ? 'collapsed-menu' : ''
      ]
    }
  },
  created() {
    // this.activeMenu=this.$route.path.replace(/^\//, '')
    // console.log(this.activeMenu)
    this.getDataList();
  },
  watch: {// 路由控制菜单选中状态
    '$route'(to) {
      console.log("$route=====",to);
      this.activeMenu = to.path;
      this.$nextTick(() => {
        this.$refs.layout_menus.updateOpened();
        this.$refs.layout_menus.updateActiveName()
      });
    }
  },
  methods: {
    collapsedSider() {
      this.$refs.side1.toggleCollapse();
    },
    selectMenu(index) {
      console.log("select", index)
      if (index !== this.activeMenu) {
        this.$router.push(index)
      }
    },
    getDataList() {
      this.menuData = [];
      this.$http.get("/backend/menus").then(res => {
        console.log(res.data);
        this.menuData = res.data.data;
        this.activeMenu = this.$route.path;

        this.$nextTick(() => {
          this.$refs.layout_menus.updateOpened();
          this.$refs.layout_menus.updateActiveName()
        });
      });
    },

  }
}
</script>
