<template>
    <div>
      <Row><h2>代理管理</h2></Row>
      <br>
      <Row>
        <Col span="24">
          <Input v-model="searchValue" clearable placeholder="请输入代理名称" style="width:300px"></Input>
          <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
        </Col>
      </Row>
        <br>

        <Row>
            <el-table :data="tableData" :cell-style="cellStyle" border size="small">
                <el-table-column prop="id" label="ID" width="50"></el-table-column>
                <el-table-column prop="name" label="机构名称"></el-table-column>
                <el-table-column prop="we_chat_number" label="提成微信号"></el-table-column>
                <el-table-column prop="contact_name" label="联系人姓名"></el-table-column>
                <el-table-column prop="contact_phone" label="联系人电话"></el-table-column>
                <el-table-column prop="commission_percentage" label="提成比例"></el-table-column>
                <el-table-column prop="pid_commission_percentage" label="上级提成比例"></el-table-column>
                <el-table-column prop="discount_percentage" label="学员充值优惠折扣"></el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <Button @click="openEditItemModel(scope.row)"  type="primary" size="small">编辑</Button>
                        <Button @click="delItem(scope.row)" type="error" size="small">删除</Button>
                    </template>
                </el-table-column>
            </el-table>
        </Row>

        <Row>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <el-pagination
                            @size-change="handlePageSizeChange"
                            @current-change="handlePageChange"
                            :current-page="nowPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal">
                    </el-pagination>
                </div>
            </div>
        </Row>

        <Modal title="新增编辑机构" v-model="editModel">
            <Form :model="oneItem">
                <Row gutter="20">
                    <Col :span="12">
                        <FormItem label="机构名称">
                            <Input v-model="oneItem.name"></Input>
                        </FormItem>

                    </Col>
                    <Col :span="12">
                        <FormItem label="提成微信号">
                            <Input v-model="oneItem.we_chat_number"></Input>
                        </FormItem>
                    </Col>
                </Row>
                <Row v-if="!oneItem.id" gutter="20">
                    <Col :span="12">
                        <FormItem label="合作伙伴id">
                            <Input v-model="oneItem.user_id"></Input>
                        </FormItem>
                    </Col>
                    <Col :span="12">
                        <FormItem label="上级代理机构id">
                            <Input v-model="oneItem.pid"></Input>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter="20">
                    <Col :span="12">
                        <FormItem label="联系人姓名">
                            <Input v-model="oneItem.contact_name"></Input>
                        </FormItem>
                    </Col>
                    <Col :span="12">
                        <FormItem label="联系人电话">
                            <Input v-model="oneItem.contact_phone"></Input>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter="20">

                    <Col :span="8">
                        <FormItem label="提成比例%">
                            <Input-number v-model="oneItem.commission_percentage" :precision="2"
                                             :max="100"></Input-number>
                        </FormItem>
                    </Col>

                    <Col :span="8">
                        <FormItem label="上级提成比例%">
                            <Input-number v-model="oneItem.pid_commission_percentage" :precision="2"
                                             :max="100"></Input-number>
                        </FormItem>
                    </Col>

                    <Col :span="8">
                        <FormItem label="学员充值折扣%">
                            <Input-number v-model="oneItem.discount_percentage" :precision="2"
                                             :max="100"></Input-number>
                        </FormItem>
                    </Col>
                </Row>


            </Form>
            <div slot="footer" class="dialog-footer">
                <Button @click="editModel = false">取 消</Button>
                <Button type="primary" @click="editItem">确 定</Button>
            </div>
        </Modal>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                nowPage: 1, // 当前页
                pageTotal: 0, // 总页数
                pageSize: 10, // 每页数量

                tableData: [],

                searchValue: '', // 搜索值
                searchType: '', // 搜索值

                oneItem: {},
                editModel: false, // 新增编辑
                detailsModel: false, // 详情弹窗
            }
        },

        created() {
            this.getDataList();
        },
        methods: {
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.getDataList()
            },
            handlePageChange(page) {
                this.nowPage = page;
                this.getDataList()
            },
            getDataList() {
                this.tableData = [];
                this.$http.get("/backend/organs", {
                    params: {
                        page: this.nowPage,
                        pre_page: this.pageSize,
                        word: this.searchValue,
                    }
                }).then(res => {
                    console.log(res.data);
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.meta.total;
                });
            },


            openAddItemModel() {
                this.oneItem = {
                    pid: "",
                    user_id: "",
                    name: "",
                    we_chat_number: "",
                    contact_name: "",
                    contact_phone: "",
                    commission_percentage: "",
                    pid_commission_percentage: "",
                    discount_percentage: "",
                };
                this.editModel = true;
            },
            openEditItemModel(row) {
                this.oneItem = {};
                this.oneItem = row;
                this.editModel = true;
            },
            openDetailsModel(index) {
                this.oneItem = {};
                this.oneItem = this.tableData[index];
                this.detailsModel = true;
            },
            editItem() {
                this.oneItem.pid=parseInt(this.oneItem.pid);
                this.oneItem.user_id=parseInt(this.oneItem.user_id);

                // this.organ.commission_percentage=parseFloat(this.organ.commission_percentage);
                // this.organ.pid_commission_percentage=parseFloat(this.organ.pid_commission_percentage);
                // this.organ.discount_percentage=parseFloat(this.organ.discount_percentage);

                this.$http.post('/backend/organ', this.oneItem).then(resData => {
                    if (200 === resData.data.code) {
                        this.$notify({
                            title: '成功',
                            message: '添加成功',
                            type: 'success'
                        });
                        this.editModel = false;
                        this.getDataList();
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: resData.data.msg
                        });
                    }
                })
            },

            chooseTree(choose) {
                console.log(choose)
                this.searchSubjectId = choose
                this.uploadData.subject_id = choose
            },
            openImportTopicModel() {
                this.importTopicModel = true
            },
            importTopic() {
                if (this.uploadData.subject_id === 0) {
                    this.$notify.error({
                        title: '错误',
                        message: "请选择要导入的科目"
                    });
                    return
                }
                if (this.$refs.upload.uploadFiles.length <= 0) {
                    this.$notify.error({
                        title: '错误',
                        message: "请选择要上传的文件"
                    });
                    return;
                }
                this.$refs.upload.submit();
            },
            handleUploadSuccess(response, file, fileList) {
                console.log("handleUploadSuccess")
                console.log(response)
                console.log(file)
                console.log(fileList)
                if (200 === response.code) {
                    this.$notify({
                        title: '成功',
                        message: '导入成功',
                        type: 'success'
                    });
                    this.importTopicModel = false
                    this.$refs.upload.uploadFiles = []
                    this.uploadData.subject_id = 0
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: response.msg
                    });
                }

            },
            handleUploadError(err, file, fileList) {
                console.log("handleUploadError")
                console.log(err)
                console.log(file)
                console.log(fileList)
                this.$notify.error({
                    title: '错误',
                    message: err
                });

            },

            delItem(row) {
                this.$http.delete('/backend/organ/' + row.id).then(resData => {
                    if (200 === resData.data.code) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success'
                        });
                        this.getDataList();
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: resData.data.msg
                        });
                    }
                });
            },

        }
    }
</script>
