<template>
    <div class="print-page">
        <div class="print-btn-div">
            <Button type="primary" class="print-btn" size="small" @click="goBack">返回</Button>
            <Button type="primary" class="print-btn" icon="el-icon-printer" size="small" @click="handlePrint">打印
            </Button>
        </div>
        <section class="print-wrapper" ref="print" v-if="user">
            <br><br>
            <div contentEditable="true" style="font-size: 30px;text-align: center;">培训合格证明</div>
            <div class="div-justify">
                <div class="company-name">
                    姓名: <span contentEditable="true">{{user.name}}</span> <br>
                    身份证号: <span contentEditable="true">{{user.id_card}}</span>
                </div>
                <div class="print-date">
                    <el-image style="width: 100px; height: 133px" :src="user.id_card_avatar">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
            </div>
            <div>
                <div contentEditable="true" style="font-size: 25px;text-align: center;">
                    {{user.subject_name}}
                </div>
                <div contentEditable="true" style="text-align: center;margin-bottom: 20px;">
                    培训内容
                </div>
            </div>

            <div class="my-table" border="1" style="margin-bottom: 10px;">
                <table>
                    <tr>
                        <th contentEditable="true">学习时间</th>
                        <th contentEditable="true">课程</th>
                        <th contentEditable="true">学时</th>
                        <th contentEditable="true">学习形式</th>
                    </tr>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td contentEditable="true" class="my-center">{{formatTime(item.start_at)}} - {{formatTime(item.end_at)}}</td>
                        <td contentEditable="true">{{item.name}}</td>
                        <td contentEditable="true" class="my-center">{{item.period}}课时</td>
                        <td contentEditable="true" class="my-center">网上学习</td>
                    </tr>
                </table>
            </div>
            <div style="text-align: right;margin-right: 40px;">
                总课时：<span contentEditable="true">{{res.period_total}}</span> 课时
            </div>


            <div contentEditable="true" style="text-align: center;margin:30px auto;">
                该学员依照国家安全生产监督管理总局制定的培训大纲，完成网络培训课程，培训合格。
            </div>
            <div class="div-justify" style="margin-right: 80px">
                <div contentEditable="true">

                </div>
                <div contentEditable="true">
                    培训机构（盖章）：
                </div>
                <div contentEditable="true">
                    学员签名：
                </div>
            </div>

        </section>
    </div>

</template>

<script>
    import Vue from 'vue';
    import Print from '../plugs/print';

    Vue.use(Print);
    export default {
        data() {
            return {
                user_id:0,
                tableData: [],
                user: {},
                res:{}
            }
        },

        created() {
            if (undefined === this.$route.query.user_id || "" === this.$route.query.user_id) {
                this.$route.push("/users")
                return
            }

            this.user_id = Number(this.$route.query.user_id)
            this.getDataList();
        },
        methods: {
            getDataList() {
                this.tableData = [];
                this.$http.get("/backend/courses/period_print", {
                    params: {
                        user_id: this.user_id,
                    }
                }).then(res => {
                    console.log(res.data);
                    this.res = res.data.data;
                    this.user = res.data.data.user;
                    this.tableData = res.data.data.periods;
                });
            },
            handlePrint() {
                this.$print(this.$refs.print);
            },
            goBack() {
                this.$router.back();
            },

            formatTime(oldTime) {
                if(!oldTime){
                    return "";
                }
                var aa = new Date(oldTime)
                var year = aa.getFullYear();
                var month = aa.getMonth() + 1;
                var day = aa.getDate();
                return year + "年" + month + "月" + day + "日";
            }


        }
    }
</script>


<style>
    /*整个页面的最外层*/
    .print-page {
        padding: 0 50px 50px 50px;
        width: 750px;
        min-height: 900px;
        border: 1px solid #000;
        background-color: #ffffff
    }
    /*打印页面的主体*/
    .print-wrapper {
        margin: 0 auto;
        width: 700px;
    }

    .div-justify {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    .my-table table {
        width: 700px;
        border: 1px solid #000;
        border-collapse: collapse;
    }
    .my-table table td, .my-table table th {
        border: 1px solid #999;
    }
    .my-center{
        text-align: center;
    }


    /*打印按钮的div*/
    .print-btn-div {
        width: 650px;
        margin: 0 auto;
        height: 50px;
    }
    /*打印按钮*/
    .print-btn {
        float: right;
        margin-left: 10px;
    }




</style>
