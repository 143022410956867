<template>
<div>
  <div class="print-btn-div">
    <Button type="primary" class="print-btn" @click="goBack">返回</Button>
    <Button type="primary" class="print-btn" icon="el-icon-printer" @click="print">打印</Button>
    <Button type="primary" class="print-btn" icon="el-icon-printer" @click="toPdf">下载为PDF</Button>
  </div>
  <div class="print-page">
      <div id="preview_content"></div>
  </div>



</div>

</template>

<script>
import Vue from 'vue';
import Print from '../plugs/print';
import printTeml from '../print/period'
import moment from "moment";
Vue.use(Print);

export default {
  data() {
    return {
      hiprintTemplate:null,
      printData: {},

      classId: 0,
      classDetails: {},

      classStudents: [],
      classLessons: [],
      classSituation:[],



      user_id: 0,
      tableData: [],
      user: {},
      res: {}
    }
  },

  created() {
    this.classId = this.$route.params.id ? this.$route.params.id : this.$route.query.id
    if (undefined === this.classId || "" === this.classId) {
      this.$router.push("/class")
    }
    this.getClassDetails();
    this.getClassSituation();
  },
  methods: {
    getClassDetails() {
      this.classDetails = {};
      this.$http.get("/backend/class/" + this.classId).then(res => {
        this.classDetails = res.data.data;
      });
    },
    getClassSituation() {
      this.tableData = [];
      var items = [];
      this.$http.get("/backend/class/" + this.classId + "/situation").then(res => {
        this.classSituation = res.data.data;

        for (let i = 0; i < res.data.data.length; i++) {
          items.push({
            "index":i+1,
            "name":this.classSituation[i].user.name,
            "id_card":this.classSituation[i].user.id_card,
            "content":this.classSituation[i].class.name,
            "num":this.classSituation[i].course_num,
            "completion_time":moment(this.classSituation[i].complete_time).format('YYYY-MM-DD'),
          })
        }
        this.printData={"table":items}
        this.preview();
      });
    },
    preview() {
      this.$pluginName.init();
      this.hiprintTemplate = new this.$pluginName.PrintTemplate({template:printTeml});
      // this.hiprintTemplate.update(printTeml)
      $('#preview_content').html(this.hiprintTemplate.getHtml(this.printData));
    },


    goBack() {
      this.$router.back();
    },
    print() {
      this.hiprintTemplate.print(this.printData, {}, {
        callback: () => {
          // this.waitShowPrinter = false
        }
      })
    },
    toPdf() {
      this.hiprintTemplate.toPdf(this.printData, '安全生产远程培训系统培训学时证明');
    },
  }
}
</script>


<style>
/*整个页面的最外层*/
.print-page {
  padding: 0 50px 50px 50px;
  width: 850px;
  min-height: 900px;
  border: 1px solid #000;
  background-color: #ffffff
}



/*打印按钮的div*/
.print-btn-div {
  width: 650px;
  margin: 0 auto;
  height: 50px;
}

/*打印按钮*/
.print-btn {
  float: right;
  margin-left: 10px;
}


</style>
