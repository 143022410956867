<template>
    <div>


      <Row><h2>充值卡号</h2></Row>
      <br>
      <Row>
        <Col span="24">
          <Input v-model="searchValue" clearable placeholder="请输入内容" :style="{marginRight:'5px',width:'300px'}"></Input>
          <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
        </Col>
      </Row>
      <br>

        <Row>
            <el-table :data="tableData" :cell-style="cellStyle" border size="small">
                <el-table-column prop="creator.nickname" label="购买人"></el-table-column>
                <el-table-column prop="product.title" label="购买商品"></el-table-column>
                <el-table-column prop="number" label="卡号"></el-table-column>
                <el-table-column prop="created_at" label="生成时间"></el-table-column>
                <el-table-column prop="user.nickname" label="使用者"></el-table-column>
                <el-table-column prop="use_time" label="使用时间"></el-table-column>
            </el-table>
        </Row>

        <Row>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <el-pagination
                            @size-change="handlePageSizeChange"
                            @current-change="handlePageChange"
                            :current-page="nowPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal">
                    </el-pagination>
                </div>
            </div>
        </Row>



    </div>
</template>

<script>
    export default {
        data() {
            return {
                nowPage: 1, // 当前页
                pageTotal: 0, // 总页数
                pageSize: 10, // 每页数量

                tableData: [],

                searchValue: '', // 搜索值
                searchType: '', // 搜索值
                searchSubjectId: '',

                oneItem: {},
                editModel: false, // 新增编辑
                detailsModel: false, // 详情弹窗

                importTopicModel: false,
            }
        },

        created() {
            this.getDataList();
        },
        methods: {
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.getDataList()
            },
            handlePageChange(page) {
                this.nowPage = page;
                this.getDataList()
            },
            getDataList() {
                this.tableData = [];
                this.$http.get("/backend/cd_keys", {
                    params: {
                        page: this.nowPage,
                        pre_page: this.pageSize,
                        word: this.searchValue,
                    }
                }).then(res => {
                    console.log(res.data);
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.meta.total;
                });
            },



            openAddItemModel() {
                this.oneItem = {
                    name: '',
                    pid: 0,
                };
                this.editModel = true;
            },
            openEditItemModel(index) {
                this.oneItem = {};
                this.oneItem = this.tableData[index];
                this.editModel = true;
            },
            openDetailsModel(index) {
                this.oneItem = {};
                this.oneItem = this.tableData[index];
                this.detailsModel = true;
            },

            chooseTree(choose) {
                console.log(choose)
                this.searchSubjectId = choose
                this.uploadData.subject_id = choose
            },
            openImportTopicModel() {
                this.importTopicModel = true
            },

        }
    }
</script>
