import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'

import service from './plugins/service.js'

import router from './router.js';


import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

Vue.use(ViewUI);

Vue.use(service)
Vue.config.productionTip = false;

import {hiPrintPlugin} from 'vue-plugin-hiprint'
Vue.use(hiPrintPlugin, '$pluginName')
hiPrintPlugin.disAutoConnect(); // 取消自动连接直接打印客户端


import $ from 'jquery'  //导入jquery
Vue.prototype.$ = $   //将$(jquery)挂在到vue.prototype身上，让所有的Vue共享这个方法

import moment from 'moment'

Vue.prototype.$moment = moment
Vue.filter('datefmt', function (input, fmtString) {
    // 使用momentJs这个日期格式化类库实现日期的格式化功能
    return moment(input).format(fmtString);
});


import axios from 'axios';

axios.defaults.baseURL = "/api";
Vue.$http = Vue.prototype.$http = axios;
// http request 拦截器
axios.interceptors.request.use(
    config => {
        if (window.localStorage.getItem('token')) {
            config.headers.Authorization = "Bearer " + window.localStorage.getItem('token');
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 401 清除token信息并跳转到登录页面
                    window.localStorage.setItem('token', '');
                    router.replace({
                        path: '/login',
                    });
            }
        }
        // console.log(JSON.stringify(error));
        //console : Error: Request failed with status code 402
        return Promise.reject(error.response.msg);
    }
);


Vue.prototype.cellStyle = function () {
    return 'padding:0';
}

new Vue({
    router: router,
    render: h => h(App),
}).$mount('#app');
