<template>
    <div>
      <Row>
        <h2>报名记录</h2>
      </Row>
      <br>
      <Row>
        <Col span="24">
          <Button type="primary" @click="toExport" :style="{marginRight:'5px'}">导出</Button>

          <el-date-picker
              v-model="searchTimes"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right" :style="{marginRight:'5px'}">
          </el-date-picker>

          <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
        </Col>
      </Row>




        <br>

        <Row>
            <el-table :data="tableData" :cell-style="cellStyle" border size="small">


                <el-table-column prop="category_name" label="报名科目"></el-table-column>
                <el-table-column prop="name" label="姓名" width="70"></el-table-column>
                <el-table-column prop="sex" label="性别" width="50"></el-table-column>
                <el-table-column prop="id_card" label="身份证"></el-table-column>
                <el-table-column prop="phone" label="联系电话" width="100"></el-table-column>
                <el-table-column prop="qualifications" label="学历"></el-table-column>
                <el-table-column prop="organ_name" label="单位名称"></el-table-column>
                <el-table-column prop="type" label="取证类型" width="50"></el-table-column>
                <el-table-column prop="created_at" label="报名时间" width="150"></el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <Button @click="download(scope.row)" type="text" size="small">导出资料</Button>
                        <Button @click="downloadWord('pxdjb',scope.row)" type="text" size="small">导出培训登记表
                        </Button>
                        <Button @click="downloadWord('khsqb',scope.row)" type="text" size="small">导出考核申请表
                        </Button>
                    </template>
                </el-table-column>
            </el-table>
        </Row>


        <Row>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <el-pagination
                            @size-change="handlePageSizeChange"
                            @current-change="handlePageChange"
                            :current-page="nowPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal">
                    </el-pagination>
                </div>
            </div>
        </Row>

    </div>
</template>

<script>
// import JSZip from 'jszip'
// import FileSaver from 'file-saver'
import {saveAs} from 'file-saver';
import SubjectTree from "@/components/subject-tree.vue";

export default {
    components: {SubjectTree},
    data() {
        return {
            nowPage: 1, // 当前页
            pageTotal: 0, // 总页数
            pageSize: 10, // 每页数量

            tableData: [],
            organs: [],

            searchValue: '', // 搜索值
            searchOrganId: '', // 代理机构id
            searchPayStatus: '',//支付状态
            searchTimes: [],//搜索日期

            oneItem: {},
            editModel: false, // 新增编辑
            detailsModel: false, // 详情弹窗

            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },

    created() {
        this.getOrgans();
        this.getDataList();
    },
    methods: {
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.getDataList()
        },
        handlePageChange(page) {
            this.nowPage = page;
            this.getDataList()
        },
        getOrgans() {
            this.$http.get("/backend/organs").then(res => {
                this.organs = res.data.data;
            });
        },
        getDataList() {
            this.tableData = [];
            var start_at = "";
            var end_at = "";
            if (null !== this.searchTimes && 2 === this.searchTimes.length) {
                start_at = this.searchTimes[0]
                end_at = this.searchTimes[1]
            }
            this.$http.get("/backend/registers", {
                params: {
                    page: this.nowPage,
                    pre_page: this.pageSize,
                    word: this.searchValue,
                    organ_id: this.searchOrganId,
                    status: this.searchPayStatus,
                    start_at: start_at,
                    end_at: end_at,
                }
            }).then(res => {
                console.log(res.data);
                this.tableData = res.data.data;
                this.pageTotal = res.data.meta.total;
            });
        },

        toExport() {
            var start_at = "";
            var end_at = "";
            if (null !== this.searchTimes && 2 === this.searchTimes.length) {
                start_at = this.searchTimes[0]
                end_at = this.searchTimes[1]
            }
            var export_filename = "报名列表-" + Date.parse(new Date()) + ".xlsx"
            const xhr = new XMLHttpRequest();
            xhr.open('get', "/api/backend/registers?export=1&start_time=" + start_at + "&end_time=" + end_at);
            xhr.setRequestHeader("Authorization", 'Bearer ' + window.localStorage.getItem('token'));
            xhr.responseType = 'blob';
            xhr.send();
            xhr.onload = function (res) {
                console.log(res)
                if (this.status === 200 || this.status === 304) {
                    // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
                    if ('msSaveOrOpenBlob' in navigator) {
                        navigator.msSaveOrOpenBlob(this.response, export_filename);
                        return;
                    }
                    // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
                    // const url = URL.createObjectURL(blob);
                    const url = URL.createObjectURL(this.response);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = export_filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
            };
        },
        downloadWord(name, row) {
            var export_filename = name+"-"+row.name+"-" + Date.parse(new Date()) + ".docx"
            const xhr = new XMLHttpRequest();
            xhr.open('get', "/api/backend/register/" + row.id + "/generate_word/" + name);
            xhr.setRequestHeader("Authorization", 'Bearer ' + window.localStorage.getItem('token'));
            xhr.responseType = 'blob';
            xhr.send();
            xhr.onload = function (res) {
                console.log(res)
                if (this.status === 200 || this.status === 304) {
                    // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
                    if ('msSaveOrOpenBlob' in navigator) {
                        navigator.msSaveOrOpenBlob(this.response, export_filename);
                        return;
                    }
                    // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
                    // const url = URL.createObjectURL(blob);
                    const url = URL.createObjectURL(this.response);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = export_filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
            };
        },

        download(row) {
            let images = []
            if (row.id_card_avatar.length > 0) {
                images.push({
                    url: row.id_card_avatar,
                    fileName: "证件照"
                })
            }
            if (row.id_card_front.length > 0) {
                images.push({
                    url: row.id_card_front,
                    fileName: "身份证正面"
                })
            }
            if (row.id_card_back.length > 0) {
                images.push({
                    url: row.id_card_back,
                    fileName: "身份证反面"
                })
            }
            if (row.education_materials.length > 0) {
                images.push({
                    url: row.education_materials,
                    fileName: "学历材料"
                })
            }
            if (images.length > 0) {
                this.wrapImageToZip(images, row.name + "-学员资料")
            } else {
                this.$notify.error({
                    title: '错误',
                    message: "该学员没有上传相关材料"
                });
            }
        },


        getBase64Image(image, ext) {
            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            let context = canvas.getContext('2d');
            context.drawImage(image, 0, 0, image.width, image.height);
            // 这里是不支持跨域的
            let base64 = canvas.toDataURL(`image/${ext}`);
            base64 = base64.substring(base64.indexOf(',') + 1);
            return base64;
        },

        wrapImageToZip(urlList, filename) {
            let imageCount = urlList.length,
                numCount = 0,
                arrBase64 = [];

            urlList.map(item => {
                let url = `${item.url}?a=${new Date().getTime()}`, image = new Image();
                image.src = url;
                image.setAttribute('crossOrigin', '*');
                image.onload = () => {
                    numCount++;
                    let ext = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'))
                    let base64 = this.getBase64Image(image, ext); //需要先将图片转换为base64
                    let itemName = item.fileName
                    arrBase64.push({ext, base64,itemName});
                    if (numCount === imageCount) {
                        Promise.all([require('jszip'), import('file-saver')]).then(([JSZip, FileSaver]) => {
                            let zip = new JSZip();
                            let img = zip.folder('');
                            for (let bItem of arrBase64.entries()) {
                                img.file(`${bItem[1].itemName}-${bItem[0]}.${bItem[1].ext}`, bItem[1].base64, {base64: true});
                            }
                            // 图片是base64格式,但不要base64前缀
                            let fileName = `${filename}.zip`;
                            zip.generateAsync({type: 'blob'}).then((content) => {
                                saveAs(content, fileName);
                            });
                        });
                    }
                };
            });
        }

    }
}
</script>
