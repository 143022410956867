<template>
    <div>
      <Row><h2>用户管理</h2></Row>
      <br>

      <Row>
        <Col span="24">
          <Button type="primary" @click="UploadIdCardAvatarModel=true" :style="{marginRight:'5px'}">导入证件照</Button>

          <Input v-model="searchValue" clearable placeholder="请输入用户id/昵称/姓名/身份证号码 搜索"
                 :style="{marginRight:'5px',width:'300px'}"></Input>

          <Button shape="circle" icon="ios-search" @click="getDataList"></Button>


        </Col>
      </Row>



        <br>

        <Row>
            <el-table :data="tableData" :cell-style="cellStyle" border size="small">

                <el-table-column prop="id" label="ID" width="66"></el-table-column>
                <el-table-column prop="avatar" label="头像" width="80">
                    <template slot-scope="scope">
                        <el-avatar :src="scope.row.avatar" shape="square"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="avatar" label="地区" width="120">
                    <template slot-scope="scope">
                        {{ scope.row.province }}{{ scope.row.city }}
                    </template>
                </el-table-column>

                <el-table-column prop="nickname" label="昵称"></el-table-column>
                <el-table-column prop="last_login_time" label="上次登陆时间"></el-table-column>
                <el-table-column prop="subject_name" label="绑定科目">
                    <template slot-scope="scope">
                        <div v-if="scope.row.subject_id>0"> {{ scope.row.subject_name }}({{ scope.row.subject_id }})
                        </div>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <Button @click="periodPrint(scope.row.id)" size="small" style="margin-right: 5px">学时打印</Button>
                        <!--                        <Button type="text" size="small">编辑</Button>-->
                        <Button @click="delItem(scope.row)" type="error" size="small">删除</Button>
                    </template>
                </el-table-column>
            </el-table>
        </Row>

        <Row>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <el-pagination
                            @size-change="handlePageSizeChange"
                            @current-change="handlePageChange"
                            :current-page="nowPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal">
                    </el-pagination>
                </div>
            </div>
        </Row>

        <Modal title="导入证件照" v-model="UploadIdCardAvatarModel">
            <el-upload
                    class="upload-demo"
                    action="/api/backend/users/upload_id_card_avatar"
                    multiple
                    drag
                    :on-success="handleUploadSuccess"
                    :on-error="handleUploadError">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
        </Modal>

    </div>
</template>

<script>
import SubjectTree from "@/components/subject-tree.vue";

export default {
    components: {SubjectTree},
    data() {
        return {
            nowPage: 1, // 当前页
            pageTotal: 0, // 总页数
            pageSize: 10, // 每页数量

            tableData: [],

            searchValue: '', // 搜索值

            oneItem: {},
            editModel: false, // 新增编辑
            detailsModel: false, // 详情弹窗

            UploadIdCardAvatarModel: false, // 上传证件照
        }
    },

    created() {
        this.getDataList();
    },
    methods: {
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.getDataList()
        },
        handlePageChange(page) {
            this.nowPage = page;
            this.getDataList()
        },
        getDataList() {
            this.tableData = [];
            this.$http.get("/backend/users", {
                params: {
                    page: this.nowPage,
                    pre_page: this.pageSize,
                    word: this.searchValue,
                }
            }).then(res => {
                console.log(res.data);
                this.tableData = res.data.data;
                this.pageTotal = res.data.meta.total;
            });
        },


        handleUploadSuccess(response, file, fileList) {
            console.log("handleUploadSuccess")
            console.log(response)
            console.log(file)
            console.log(fileList)
            if (200 !== response.code) {
                this.$notify.error({
                    title: '错误',
                    message: response.msg
                });
            }
        },
        handleUploadError(err) {
            this.$notify.error({
                title: '上传失败',
                message: err
            });
        },


        openAddItemModel() {
            this.oneItem = {
                name: '',
                pid: 0,
            };
            this.editModel = true;
        },
        openEditItemModel(index) {
            this.oneItem = {};
            this.oneItem = this.tableData[index];
            this.editModel = true;
        },
        openDetailsModel(index) {
            this.oneItem = {};
            this.oneItem = this.tableData[index];
            this.detailsModel = true;
        },


        delItem(row) {
            this.$http.delete('/backend/topic/' + row.id).then(resData => {
                if (200 === resData.data.code) {
                    this.$notify({
                        title: '成功',
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: resData.data.msg
                    });
                }
            });
        },
        periodPrint(userId) {
            this.$router.push({path: '/period_print', query: {user_id: userId}});
        },

    }
}
</script>
