<template>
  <el-card>
    <div slot="header" class="main-page-title"><span>科目管理</span></div>
    <Row>
      <Button type="primary" @click="openAddItemModel()" size="small">新增</Button>
    </Row>
    <br>


    <Row>
      <el-table :data="tableData" border :cell-style="cellStyle" size="small" default-expand-all row-key="id"
                :tree-props="{children: 'children'}">
        <el-table-column prop="name" label="科目"></el-table-column>
        <el-table-column prop="exam" label="考试相关">
          <template slot-scope="scope">
            单选({{ scope.row.exam_radio_num }}),
            判断({{ scope.row.exam_judge_num }}),
            多选({{ scope.row.exam_checkbox_num }});
            考试时间({{ scope.row.exam_time }})
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <Button @click="openAddItemModel(scope.row)" type="success" size="small" :style="{marginRight:'5px'}">新增下一级</Button>
            <Button @click="openEditItemModel(scope.row)" type="primary" size="small" :style="{marginRight:'5px'}">编辑</Button>
            <Button @click="delItem(scope.row)" type="warning" size="small" :style="{marginRight:'5px'}">删除</Button>
            <Button @click="delTopics(scope.row)" type="error" size="small">清空题库</Button>
          </template>
        </el-table-column>
      </el-table>
    </Row>

    <Modal title="新增编辑科目" v-model="editModel" width="80%">
      <Form :model="oneItem">
        <FormItem label="科目名称">
          <Input v-model="oneItem.name"></Input>
        </FormItem>
        <FormItem label="考试时间(秒)">
          <Input-number v-model="oneItem.exam_time" :min="0" :max="999999"></Input-number>
        </FormItem>

        <h2>组卷规则设置(二选一)</h2>

        <h4>按题目类型</h4>
        <FormItem label="抽取单选题数量">
          <Input-number v-model="oneItem.exam_radio_num" :min="0" :max="999999"></Input-number>
        </FormItem>
        <FormItem label="抽取判断题数量">
          <Input-number v-model="oneItem.exam_judge_num" :min="0" :max="999999"></Input-number>
        </FormItem>
        <FormItem label="抽取多选题数量">
          <Input-number v-model="oneItem.exam_checkbox_num" :min="0" :max="999999"></Input-number>
        </FormItem>
        <h4>按规则类型(优先)</h4>
        <p v-for="(item,index) in oneItem.topic_rules" :key="index">
          抽取「{{item.number}}」个，{{ item.cate !== '' ? '类别='+item.cate: ""}}  {{ item.type !== 0 ? '并且 类型='+item.type: ""}}  {{ item.score !== 0 ? '并且 分值='+item.score: ""}}的题目
        </p>
        <Row>
          <template>
            <el-table
                :data="oneItem.topic_rules"
                border
                style="width: 100%">
              <el-table-column label="类别(选填)">
                <template slot-scope="scope">
                  <Input v-model="scope.row.cate"></Input>
                </template>
              </el-table-column>
              <el-table-column label="类型">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.type" placeholder="题目类型">
                    <el-option label="不限" :value="0"></el-option>
                    <el-option label="单选" :value="1"></el-option>
                    <el-option label="判断" :value="2"></el-option>
                    <el-option label="多选" :value="3"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="分值(0表示不限制)">
                <template slot-scope="scope">
                  <Input-number v-model="scope.row.score" :min="0" :max="999999"></Input-number>
                </template>
              </el-table-column>
              <el-table-column label="数量">
                <template slot-scope="scope">
                  <Input-number v-model="scope.row.number" :min="0" :max="999999"></Input-number>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="200">
                <template slot-scope="scope">
                  <Button @click="addTopicRule()" type="primary" size="mini">增加</Button>
                  <Button @click="delTopicRule(scope.$index)" type="danger" size="mini">删除</Button>
                </template>
              </el-table-column>
              <template slot="empty">
                <Button @click="addNewTopicRule()" type="danger" size="mini">立即添加</Button>
              </template>
            </el-table>
          </template>


        </Row>


      </Form>
      <div slot="footer" class="dialog-footer">
        <Button @click="editModel = false">取 消</Button>
        <Button type="primary" @click="editItem">确 定</Button>
      </div>
    </Modal>


  </el-card>
</template>

<script>
export default {
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      tableData: [],

      searchValue: '', // 搜索值

      oneItem: {
        name: '',
        pid: 0,
        exam_time: '',
        exam_radio_num: 0,
        exam_checkbox_num: 0,
        exam_judge_num: 0,
        topic_rules: [
          {cate: "", score: 0, number: 0}
        ]
      },
      editModel: false, // 新增编辑
      detailsModel: false, // 详情弹窗
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },
    getDataList() {
      this.tableData = [];
      this.$http.get("/common/subject/tree", {
        params: {
          word: this.searchValue,
        }
      }).then(res => {
        console.log(res.data);
        this.tableData = res.data.data;
      });
    },
    openAddItemModel(row) {
      let pid = 0;
      if (undefined !== row) {
        pid = row.id
      }
      this.oneItem = {
        name: '',
        pid: pid,
        topic_rules: [{id: 0, cate: "", number: 0, score: 0}]
      };
      this.editModel = true;
    },
    openEditItemModel(row) {
      this.oneItem = {};
      if (undefined === row.topic_rules || row.topic_rules.length <= 0) {
        row.topic_rules = [{id: 0, cate: "", number: 0, score: 0}]
      }
      this.oneItem = row;
      this.editModel = true;
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.detailsModel = true;
    },

    editItem() {
      this.$http.post('/backend/subject', this.oneItem).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          });
          this.editModel = false;
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      })
    },
    delItem(row) {
      this.$http.delete('/backend/subject/' + row.id).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      });
    },
    delTopics(row) {
      this.$http.delete('/backend/topics/' + row.id).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      });
    },

    addTopicRule() {
      console.log("addNewTopicRule")
      console.log(this.oneItem.topic_rules)
      if (undefined === this.oneItem.topic_rules) {
        this.oneItem.topic_rules = [];
      }
      var topic_rule = {id: 0, score: 0, number: 0}

      this.oneItem.topic_rules = this.oneItem.topic_rules.concat(topic_rule);
    },
    delTopicRule(index) {
      console.log(index)
      this.oneItem.topic_rules.splice(index, 1)
    },
  }
}
</script>
