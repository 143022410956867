<template>
    <div id="login">
        <div class="loginCon">
            <div class="title"><p>安全及特种作业模拟考试</p></div>
            <el-card shadow="always" class="login-module">


                <el-tabs v-model="activeTab">
                    <el-tab-pane label="扫码登陆" name="qr_code">
                        <div style="text-align: center">
                            请使用微信扫一扫
                            <div>
                                <vue-qr :text="qr_code.qr_code" :size="200"></vue-qr>
                            </div>
                            <div v-if="qr_code.expire_seconds>0">
                                还有{{qr_code.expire_seconds}}秒过期
                            </div>
                            <div v-else>
                                <Button type="danger" @click="getQrCode">二维码已过期，点击刷新</Button>
                            </div>

                            <br>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="密码登陆" name="password">
                        <Form :model="param" status-icon label-width="100px" class="demo-ruleForm">
                            <FormItem>
                                <Input type="text" v-model="param.username" placeholder="请输入登录账号"></Input>
                            </FormItem>
                            <FormItem>
                                <Input type="password" v-model="param.password" placeholder="请输入登录密码"></Input>
                            </FormItem>
                            <FormItem>
                                <Button class="subBtn" type="primary" @click="login">登录</Button>
                            </FormItem>
                        </Form>
                    </el-tab-pane>
                </el-tabs>

            </el-card>
        </div>
    </div>
</template>

<script>
    import vueQr from 'vue-qr'
    export default {
        components: {
            vueQr
        },
        data() {
            return {
                activeTab:"password",
                param: {
                    username: '',
                    password: '',
                },
                qr_code:{
                    expire_seconds: 0,
                    login_key: "",
                    qr_code: "",
                },
                timer: '',
            };
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        created() {
            this.getQrCode()
        },
        methods: {
            login() {
                if (this.param.username.length <= 3 || this.param.password.length <= 5) {
                    this.$notify.error({title: '错误', message: "请输入用户名和密码!"});
                    return
                }

                this.$http.post("/backend/login", {
                    username: this.param.username,
                    password: this.param.password
                }).then(res => {
                    console.log(res.data);
                    if (200 === res.data.code) {
                        // Vue.prototype.$userDetails = res.data.data.user
                        localStorage.setItem("token", res.data.data);
                        this.$router.push('/');
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: res.data.msg
                        });
                    }
                }).catch(res => {
                    console.log(res);
                    this.$notify.error({
                        title: '错误',
                        message: "请求错误，请稍后重试!"
                    });
                });
            },
            getQrCode(){
                clearInterval(this.timer);
                this.$http.get("/backend/login/qr_code").then(res => {
                    console.log(res.data);
                    if (200 === res.data.code) {
                        this.qr_code=res.data.data

                        var that = this
                        this.timer = setInterval(() => {
                            that.getQrCodeStatus()
                        }, 5000);
                        this.activeTab="qr_code"
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: res.data.msg
                        });
                    }
                }).catch(res => {
                    console.log(res);
                    this.$notify.error({
                        title: '错误',
                        message: "请求错误，请稍后重试!"
                    });
                });
            },
            getQrCodeStatus(){
                if (this.qr_code.expire_seconds<=0){
                    clearInterval(this.timer);
                    return
                }
                this.qr_code.expire_seconds-=5;
                this.$http.get("/backend/login/qr_code/status",{params:{
                        login_key:this.qr_code.login_key
                    }}).then(res => {
                    // console.log(res.data);
                    if (200 === res.data.code) {
                        localStorage.setItem("token", res.data.data);
                        this.$router.push('/');
                    } else {
                        // this.$notify.error({
                        //     title: '错误',
                        //     message: res.data.msg
                        // });
                    }
                }).catch(() => {
                    // console.log(res);
                    this.$notify.error({
                        title: '错误',
                        message: "请求错误，请稍后重试!"
                    });
                });
            }
        }
    }
</script>
<style lang="scss">
    #login {
        width: 100%;
        min-height: 800px;
        background-color: #2d3a4b;
        background-size:100% 100%;
        background-repeat:no-repeat;
        background-attachment: fixed;

        .loginCon {
            width: 444px;
            margin: auto;
            padding-top: 100px;

            .el-card__header {
                border-bottom: 0px;
            }

            .title {
                font-size: 36px;
                font-weight: 600;
                color: #ffffff;
            }

            .login-module {
                height: 350px;
                margin-top: 30px;
                border: none;
                right: 0;

                .formTitlt {
                    font-size: 18px;
                    font-weight: 400;
                }
            }

            .FormItem__content {
                margin-left: 0px !important;

                .subBtn {
                    width: 100%;
                }
            }
        }
    }
</style>
