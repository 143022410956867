<template>
  <el-card>
    <div slot="header" class="main-page-title"><span>角色管理</span></div>

    <Row :gutter="20">
      <Button type="primary" @click="openAddItemModel()" size="small">新增</Button>
    </Row>
    <br>

    <Row>
      <el-table :data="tableData" :cell-style="cellStyle" border size="small">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="alias" label="别名"></el-table-column>
        <el-table-column prop="user_ids" label="用户数">
          <template slot-scope="scope">
            {{ scope.row.user_ids.length }}
          </template>
        </el-table-column>
        <el-table-column prop="menu_ids" label="菜单数">
          <template slot-scope="scope">
            {{ scope.row.menu_ids.length }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <Button @click="openEditItemModel(scope.row)" type="primary" size="small">编辑</Button>
            <Button @click="delItem(scope.row)" type="error" size="small">删除</Button>
          </template>
        </el-table-column>
      </el-table>
    </Row>

    <Row>
      <div style="margin: 10px;overflow: hidden">
        <div style="float: right;">
          <el-pagination
              @size-change="handlePageSizeChange"
              @current-change="handlePageChange"
              :current-page="nowPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal">
          </el-pagination>
        </div>
      </div>
    </Row>

    <Modal title="新增编辑角色" v-model="editModel">
      <Form :model="oneItem">
        <FormItem label="名称">
          <Input v-model="oneItem.name"></Input>
        </FormItem>
        <FormItem label="别名">
          <Input v-model="oneItem.alias"></Input>
        </FormItem>
        <FormItem label="菜单">
          <br>
          <el-checkbox-group v-model="oneItem.menu_ids">
            <el-checkbox v-for="(menu,index) in menus" :key="index" :label="menu.id">{{ menu.name }}</el-checkbox>
          </el-checkbox-group>
        </FormItem>

        <FormItem label="用户">
          <br>
          <el-checkbox-group v-model="oneItem.user_ids">
            <el-checkbox v-for="(userId,index) in oneItem.user_ids" :key="index" :label="userId"></el-checkbox>
          </el-checkbox-group>
        </FormItem>

        <Input size="mini" clearable v-model="addUserId">
          <Button slot="append" @click="addUserToRole" icon="el-icon-plus"></Button>
        </Input>
      </Form>
      <div slot="footer" class="dialog-footer">
        <Button @click="editModel = false">取 消</Button>
        <Button type="primary" @click="editItem">确 定</Button>
      </div>
    </Modal>


  </el-card>
</template>

<script>
export default {
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      tableData: [],
      menus: [],

      addUserId: '',

      searchValue: '', // 搜索值
      searchType: '', // 搜索值

      oneItem: {},
      editModel: false, // 新增编辑
      detailsModel: false, // 详情弹窗
    }
  },

  created() {
    this.getDataList();
    this.getMenusAll();
  },
  methods: {
    addUserToRole() {
      let userId = parseInt(this.addUserId);
      console.log(userId);
      if (!userId) {
        this.$notify.error({
          title: '错误',
          message: "请输入用户ID"
        });
        return
      }
      if (this.oneItem.user_ids.includes(userId)) {
        this.$notify.error({
          title: '错误',
          message: "已存在该用户"
        });
        return
      }
      this.oneItem.user_ids.push(parseInt(this.addUserId))
      this.addUserId = ""
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },
    getDataList() {
      this.tableData = [];
      this.$http.get("/backend/roles", {
        params: {
          page: this.nowPage,
          pre_page: this.pageSize,
          word: this.searchValue,
        }
      }).then(res => {
        console.log(res.data);
        this.tableData = res.data.data;
        this.pageTotal = res.data.meta.total;
      });
    },
    getMenusAll() {
      this.menus = [];
      this.$http.get("/backend/menus_all").then(res => {
        this.menus = res.data.data;
      });
    },

    openAddItemModel() {
      this.oneItem = {
        name: "",
        alias: "",
        intro: "",
        user_ids: [],
        menu_ids: [],
      };
      this.addUserId = ""
      this.editModel = true;
    },
    openEditItemModel(row) {
      this.oneItem = {};
      this.oneItem = row;
      this.addUserId = ""
      this.editModel = true;
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.detailsModel = true;
    },
    editItem() {
      this.$http.post('/backend/role', this.oneItem).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          });
          this.editModel = false;
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      })
    },

    openImportTopicModel() {
      this.importTopicModel = true
    },

    delItem(row) {
      this.$http.delete('/backend/role/' + row.id).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      });
    },

  }
}
</script>
