<template>
  <div>
    <Row>
      <h2>班级详情</h2>
      <Button type="text" size="small" @click="goBack">返回</Button>
    </Row>
    <br>
    <Row>
      <Card :bordered="true" :style="{width:'100%'}">
        <p slot="title">班级基本信息</p>
        <Row :gutter="16">
          <Col span="12"><strong>班级名称:</strong>{{ classDetails.name }}</Col>
          <Col span="12"><strong>班级时间:</strong>
            {{ classDetails.start_time | datefmt('YYYY-MM-DD') }}-{{
              classDetails.expired_time | datefmt('YYYY-MM-DD')
            }}
          </Col>
          <Col span="12"><strong>课程验证方式:</strong>验证码-{{ classDetails.verify_mode }}</Col>
          <Col span="12"><strong>班 主 任:</strong>{{ classDetails.teacher.name }}</Col>
          <Col span="12"><strong>创建人:</strong>{{ classDetails.creator.name }}</Col>
          <Col span="12"><strong>创建时间:</strong>{{ classDetails.created_at | datefmt('YYYY-MM-DD') }}</Col>
          <Col span="12"><strong>所属机构:</strong>{{ classDetails.organ.name }}</Col>
          <Col span="12"><strong>二维码:</strong>
            <Button type="text" size="small" @click="classQrCodeModel=true">点击查看</Button>
          </Col>
        </Row>
      </Card>
    </Row>
    <br>

    <Row>
      <Tabs :value="activeTab" :style="{width:'100%'}" @on-click="tabChoose">
        <TabPane label="学员列表" name="students" :style="{width:'100%'}">
          <Row>
            <Col span="24">
              <Input v-model="searchValue" clearable placeholder="请输入学员姓名" style="width:300px"
                     :style="{marginRight:'5px'}"></Input>
              <Button shape="circle" icon="ios-search" @click="getDataList"></Button>

              <Divider type="vertical"/>
              <Button type="primary" :style="{marginRight:'5px'}" @click="toExport">上传头像</Button>
              <Button type="primary" :style="{marginRight:'5px'}" @click="toExport">调班</Button>
              <Button type="primary" @click="toExport">导出</Button>
            </Col>
          </Row>
          <br>
          <Row>
            <Table :columns="classStudentsColumns" :data="classStudents" border size="small"
                   :loading="tableLoading"></Table>
          </Row>
        </TabPane>
        <TabPane label="班级课程" name="lessons">
          <Row>
            <Col span="24">
              共有{{ classLessons.length }}门课，累计{{ classLessons.length }}课时
              <Divider type="vertical"/>
              <Button type="primary" @click="toExport">添加课程</Button>
            </Col>
          </Row>
          <br>
          <Table :columns="classLessonsColumns" :data="classLessons" border size="small"></Table>
        </TabPane>
        <TabPane label="证书管理" name="certificat" :style="{width:'100%'}">
          <Row>
            <Col span="24">
              <Input v-model="searchValue" clearable placeholder="请输入学员姓名/身份号查找" style="width:300px"
                     :style="{marginRight:'5px'}"></Input>
              <Select style="width: 120px;margin-right: 5px" v-model="searchPayStatus" clearable
                      placeholder="请选择证书状态">
                <Option label="废弃" value="0"></Option>
                <Option label="有效" value="-1"></Option>
                <Option label="未取得" value="1"></Option>
              </Select>
              <Select style="width: 120px;margin-right: 5px" v-model="searchPayStatus" clearable
                      placeholder="请选择打印状态">
                <Option label="未打印" value="0"></Option>
                <Option label="已打印" value="1"></Option>
              </Select>
              <Button shape="circle" icon="ios-search" @click="getClassSituation"></Button>

              <Divider type="vertical"/>
              <router-link :to="'/class/print/cert?id='+classId">
                <Button type="primary" :style="{marginRight:'5px'}">打印合格证书</Button>
              </router-link>
              <router-link :to="'/class/print/period?id='+classId">
                <Button type="primary" :style="{marginRight:'5px'}">打印学时证明</Button>
              </router-link>
              <Button type="primary" @click="toExport">打印合格证明</Button>
            </Col>
          </Row>
          <br>
          <Row>
            <Table :columns="classSituationColumns" :data="classSituation" border size="small"
                   :loading="tableLoading"></Table>
          </Row>
        </TabPane>

      </Tabs>
    </Row>

    <Modal v-model="classQrCodeModel" title="班级二维码" @on-ok="classQrCodeModel=false">
      <vue-qr size="300" :correctLevel="3" :text="classDetails.qrcode"></vue-qr>
      <div slot="footer"></div>
    </Modal>


    <Modal title="编辑班级课程" v-model="classLessonEditModel" width="80%">
      <Form :model="oneItem" :label-width="80">

        <FormItem label="选择课程">
          <el-transfer :list-style="{width: '600px'}" v-model="oneItem.course_ids"
                       :titles="['待选择', '已选择']"
                       :button-texts="['移除', '增加']"
                       :data="courses"></el-transfer>
        </FormItem>
      </Form>

      <div slot="footer" class="dialog-footer">
        <Button @click="editModel = false">取 消</Button>
        <Button type="primary" @click="editItem">确 定</Button>
      </div>
    </Modal>


  </div>
</template>


<script>

import VueQr from "vue-qr";
import moment from "moment/moment";


export default {
  components: {
    VueQr
  },
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      activeTab: "students",

      classQrCodeModel: false,


      bj: {
        name: ""
      },


      searchValue: '', // 搜索值

      oneItem: {},
      editModel: false, // 新增编辑
      detailsModel: false, // 详情弹窗
      tableLoading: false,

      UploadIdCardAvatarModel: false, // 上传证件照

      classLessonEditModel: false,


      classId: 0,
      classDetails: {},

      classStudentsColumns: [
        {title: 'id', width: 100, key: 'id'},
        {title: '姓名', width: 160, key: 'name'},
        {title: '手机号', width: 120, key: 'phone'},
        {title: '身份证号', width: 160, key: 'id_card'},
        {title: '加入时间', width: 150, key: 'join_at'},
        {title: '当前绑定科目', key: 'subject_name'},
        {
          title: '操作', width: 220, key: 'action', align: 'center', render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.openEditItemModel(params.row)
                  }
                }
              }, '学习记录'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delItem(params.row)
                  }
                }
              }, '移除班级')
            ]);
          }
        }
      ],
      classStudents: [],

      classLessonsColumns: [
        {title: 'id', width: 100, key: 'id'},
        {title: '课程名称', key: 'name'},
        {
          title: '所属科目', key: 'subject_name', render: (h, params) => {
            return h('span', params.row.subject.full_name);
          }
        },
        {
          title: '课程类型', width: 100, key: 'category', render: (h, params) => {
            if (params.row.category === 2) {
              return h('span', "实操视频");
            } else {
              return h('span', "理论学习");
            }
          }
        },
        {
          title: '课时数', key: 'courses_num', render: (h, params) => {
            return h('span', params.row.lesson.courses_num);
          }
        },
        {title: '排序', width: 80, key: 'rank'},
        {
          title: '操作', width: 140, key: 'action', align: 'center', render: (h, params) => {
            return h('div', [

              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delItem(params.row)
                  }
                }
              }, '移除')
            ]);
          }
        }
      ],
      classLessons: [],

      classSituationColumns: [
        {
          title: '姓名', width: 150, key: 'name', render: (h, params) => {
            return h('span', params.row.user.name);
          }
        },
        {
          title: '身份证号', width: 160, key: 'id_card', render: (h, params) => {
            return h('span', params.row.user.id_card);
          }
        },
        {
          title: '证书状态', width: 100, key: 'cert_state', render: (h, params) => {
            switch (params.row.cert_state) {
              case -1:
                return h('span', "无效");
              case 0:
                return h('span', "未取得");
              case 1:
                return h('span', "有效");
            }

          }
        },
        {
          title: '是否打印', width: 100, key: 'cert_print', render: (h, params) => {
            if (params.row.cert_print === 1) {
              return h('span', "已打印");
            } else {
              return h('span', "未打印");
            }
          }
        },
        {
          title: '获得时间', width: 110, key: 'complete_time', render: (h, params) => {
            return h('span', moment(params.row.complete_time).format('YYYY-MM-DD'));
          }
        },
        {
          title: '操作', width: 200, key: 'action', align: 'center', render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  to:'/class/print/cert?id=' + params.row.class_id + '&user_id=' + params.row.user_id,
                  type: 'primary',
                  size: 'small'
                },style: {
                  marginRight: '5px'
                },}, '查看证书'),


              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delItem(params.row)
                  }
                }
              }, '作废')
            ]);
          }
        }
      ],
      classSituation: [],
    }
  },

  created() {
    this.classId = this.$route.params.id ? this.$route.params.id : this.$route.query.id
    if (undefined === this.classId || "" === this.classId) {
      this.$router.push("/class")
    }
    this.activeTab = this.$route.query.tab ? this.$route.query.tab : "students"
    console.log("======", this.$route.query.tab, this.activeTab)

    this.getClassDetails();
    this.getClassStudents();
    this.getClassLessons();
    this.getClassSituation();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },

    tabChoose(name) {
      history.replaceState(null, null, "?id=" + this.classId + "&tab=" + name);
    },

    getClassDetails() {
      this.classDetails = {};
      this.$http.get("/backend/class/" + this.classId).then(res => {
        this.classDetails = res.data.data;
      });
    },
    getClassLessons() {
      this.classLessons = [];
      this.$http.get("/backend/class/" + this.classId + "/lessons").then(res => {
        this.classLessons = res.data.data;
      });
    },
    getClassStudents() {
      this.classStudents = [];
      this.$http.get("/backend/class/" + this.classId + "/students").then(res => {
        this.classStudents = res.data.data;
      });
    },
    getClassSituation() {
      this.classSituation = [];
      this.$http.get("/backend/class/" + this.classId + "/situation").then(res => {
        this.classSituation = res.data.data;
      });
    },


    handleUploadSuccess(response, file, fileList) {
      console.log("handleUploadSuccess")
      console.log(response)
      console.log(file)
      console.log(fileList)
      if (200 !== response.code) {
        this.$notify.error({
          title: '错误',
          message: response.msg
        });
      }
    },
    handleUploadError(err) {
      this.$notify.error({
        title: '上传失败',
        message: err
      });
    },
    toExport() {
      var export_filename = "学员统计表-" + Date.parse(new Date()) + ".xlsx"
      const xhr = new XMLHttpRequest();
      xhr.open('get', "/api/backend/class_students?export=1&class_id=" + this.classId);
      xhr.setRequestHeader("Authorization", 'Bearer ' + window.localStorage.getItem('token'));
      xhr.responseType = 'blob';
      xhr.send();
      xhr.onload = function (res) {
        console.log(res)
        if (this.status === 200 || this.status === 304) {
          // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
          if ('msSaveOrOpenBlob' in navigator) {
            navigator.msSaveOrOpenBlob(this.response, export_filename);
            return;
          }
          // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
          // const url = URL.createObjectURL(blob);
          const url = URL.createObjectURL(this.response);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = export_filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }
      };
    },


    openAddItemModel() {
      this.oneItem = {
        name: '',
        pid: 0,
      };
      this.editModel = true;
    },
    openEditItemModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.editModel = true;
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.detailsModel = true;
    },


    delItem(row) {
      this.$http.delete('/backend/class_students/' + this.classId + '/' + row.id).then(resData => {
        if (200 === resData.data.code) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.getDataList();
        } else {
          this.$notify.error({
            title: '错误',
            message: resData.data.msg
          });
        }
      });
    },
    periodPrint(userId) {
      this.$router.push({path: '/period_print', query: {user_id: userId}});
    },

  }
}
</script>





