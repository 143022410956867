<template>
  <div>
    <Row><h2>充值记录</h2></Row>
    <br>

    <Row>
      <Col span="24">
        <Input v-model="searchValue" clearable placeholder="请输入内容"
               :style="{marginRight:'5px',width:'200px'}"></Input>

        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
      </Col>
    </Row>
    <br>

    <Row>
      <el-table :data="tableData" :cell-style="cellStyle" border size="small">
        <el-table-column prop="user" label="用户" width="125">
          <template slot-scope="scope">
            {{ scope.row.user.nickname }}(ID:{{ scope.row.user.id }})
          </template>
        </el-table-column>
        <el-table-column prop="subject" label="充值科目">
          <template slot-scope="scope">
            <div v-if="scope.row.subject.id>0">{{ scope.row.subject.name }}(ID:{{ scope.row.subject.id }})</div>
          </template>
        </el-table-column>
        <el-table-column prop="start_time" label="开始时间"></el-table-column>
        <el-table-column prop="expired_time" label="过期时间"></el-table-column>
      </el-table>
    </Row>

    <Row>
      <div style="margin: 10px;overflow: hidden">
        <div style="float: right;">
          <el-pagination
              @size-change="handlePageSizeChange"
              @current-change="handlePageChange"
              :current-page="nowPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal">
          </el-pagination>
        </div>
      </div>
    </Row>


  </div>
</template>

<script>
export default {
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      tableData: [],

      searchValue: '', // 搜索值
      searchType: '', // 搜索值
      searchSubjectId: '',

      oneItem: {},
      editModel: false, // 新增编辑
      detailsModel: false, // 详情弹窗

      importTopicModel: false,
    }
  },

  created() {
    this.getDataList();
  },
  methods: {
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },
    getDataList() {
      this.tableData = [];
      this.$http.get("/backend/vips", {
        params: {
          page: this.nowPage,
          pre_page: this.pageSize,
          word: this.searchValue,
        }
      }).then(res => {
        console.log(res.data);
        this.tableData = res.data.data;
        this.pageTotal = res.data.meta.total;
      });
    },


    openAddItemModel() {
      this.oneItem = {
        name: '',
        pid: 0,
      };
      this.editModel = true;
    },
    openEditItemModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.editModel = true;
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.tableData[index];
      this.detailsModel = true;
    },

    chooseTree(choose) {
      console.log(choose)
      this.searchSubjectId = choose
      this.uploadData.subject_id = choose
    },
    openImportTopicModel() {
      this.importTopicModel = true
    },

  }
}
</script>
